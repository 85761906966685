import React from "react";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { filterProducts, countProducts, filterProductsByCampaign } from "../services/productService";
import { ReturnContext } from "./ReturnContext";

export const FilterContext = React.createContext();

export const startFilter = function () {
  return {
    campaignId: 0,
    searchTerm: "",
    programs: [],
    itemGroups: [],
    itemCategories: [],
    productGroups: [],
    collections: [],
    colors: [],
    sizes: [],
    genders: [],
    tags: [],
    page: 0,
    trigger: 0,
    pageSize: 50,
    orderBy: "",
    orderDesc: true,
  };
};

const FilterContextProvider = (props) => {
  const [filters, setFilters] = React.useState(startFilter);
  const { invoice } = React.useContext(ReturnContext);
  const queryClient = useQueryClient();

  const hasFilter = () => {
    if (
      filters.campaignId > 0 ||
      filters.searchTerm !== "" ||
      filters.programs.length > 0 ||
      filters.itemGroups.length > 0 ||
      filters.itemCategories.length > 0 ||
      filters.productGroups.length > 0 ||
      filters.collections.length > 0 ||
      filters.colors.length > 0 ||
      filters.sizes.length > 0 ||
      filters.genders.length > 0 ||
      filters.tags.length > 0
    ) {
      return true;
    } else {
      return false;
    }
  };
  const productQueryKey = ["products"];
  const productsQuery = useQuery(productQueryKey, async () => filterProducts(filters), {
    enabled: false,
    refetchOnWindowFocus: false,
    staleTime: 1000 * 60 * 5,
  });

  const countsQuery = useQuery(["counts", filters], async () => countProducts(filters), {
    enabled: true,
    refetchOnWindowFocus: false,
    staleTime: 1000 * 60 * 5,
  });

  const clearFilters = () => {
    setFilters(startFilter);
    queryClient.setQueryData(productQueryKey, []);
  };

  const loadMore = () => {
    setFilters((f) => {
      return { ...f, pageSize: f.pageSize + 50 };
    });
    productsQuery.refetch();
  };

  React.useEffect(() => {
    console.dir(filters);
  }, [filters]);

  React.useEffect(() => {
    if (filters.campaignId === 0) return;
    if (productsQuery.isLoading || productsQuery.isFetching || productsQuery.isRefetching) return;
    productsQuery.refetch();
  }, [filters.campaignId]);

  return (
    <FilterContext.Provider
      value={{
        countsQuery,
        filters,
        setFilters,
        clearFilters,
        loadMore,
        hasFilter,
        productsQuery,
      }}
    >
      <>{props.children}</>
    </FilterContext.Provider>
  );
};

export default FilterContextProvider;
