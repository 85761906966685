import { Card, CardContent, CircularProgress, Grid, Typography } from "@mui/material";
import AdyenCheckout from "@adyen/adyen-web";
import React from "react";
import { ReturnContext } from "../contexts/ReturnContext";
import { getSessionObject, storeSessionObject } from "../services/storageService";
import strings from "../localization/localizedStrings";
import { useHistory } from "react-router-dom";

export default function HandleRedirectPage(props) {
  const [refundOption, setRefundOption] = React.useState(null);
  const [message, setMessage] = React.useState("Processing payment result ...");
  const {
    invoice,
    replacementItems,
    setErrorState,
    createReturn,
    returnMode,
    setReturnConf,
    setInvoiceInternal,
    setReplacementItems,
    setReturnMode,
  } = React.useContext(ReturnContext);

  const history = useHistory();

  const callCreateReturn = async (rOption, checkoutResultCode, checkoutSessionData, checkoutSessionResult) => {
    storeSessionObject("checkoutOrderNo", invoice.header?.orderNo);
    storeSessionObject("checkoutEmail", invoice.header?.email);
    storeSessionObject("checkoutReturnMode", returnMode);
    storeSessionObject("checkoutCartNo", replacementItems?.cartNumber);
    await createReturn(rOption, checkoutResultCode, checkoutSessionData, checkoutSessionResult);
  };

  React.useEffect(() => {
    const invoice = getSessionObject("invoice");
    const returnConf = getSessionObject("returnConf");
    const replacementItems = getSessionObject("replacementItems");
    const returnMode = getSessionObject("checkoutReturnMode");
    const refundOption = getSessionObject("adyenCheckoutRefundoption");

    //Restore states used in ReturnContext that is needed to create return, they are wiped at checkout because payment redirect.
    setInvoiceInternal(invoice);
    setReturnConf(returnConf);
    setReplacementItems(replacementItems);
    setReturnMode(returnMode);
    setRefundOption(refundOption);

    strings.setLanguage(invoice.header.language);
  }, []);

  React.useEffect(() => {
    if (refundOption === null) return;

    const handleRedirect = async () => {
      // Get the query string from the current URL
      const queryString = window.location.search;

      // Create a URLSearchParams object with the query string
      const params = new URLSearchParams(queryString);

      // Read the desired query string parameter
      const sessionId = params.get("sessionId");
      const redirectResult = params.get("redirectResult");
      const cartNumber = params.get("cartNumber");

      const adyenCheckoutKey = getSessionObject("adyenCheckoutKey");
      const adyenSession = getSessionObject("adyenCheckoutSession");

      const adyenConfiguration = {
        environment: adyenSession.environment,
        clientKey: adyenCheckoutKey,
        analytics: {
          enabled: true,
        },
        session: {
          id: sessionId,
        },
        onPaymentCompleted: async (result, component) => {
          //console.log("onPaymentCompleted:", result);
          if (result.resultCode !== "Authorised") {
            setMessage("Payment result: " + result.resultCode);
            window.setTimeout(() => {
              history.push("/confirm");
            }, 2000);
          } else {
            await callCreateReturn(refundOption, result.resultCode, result.sessionData, result.sessionResult);
            history.push("/thanks");
          }
        },
        onError: (error, component) => {
          console.error(error);
          console.error(component);
          setErrorState({ hasError: true, errorMsg: error.message });
        },
      };

     // console.log("adyenConfiguration", adyenConfiguration);

      const checkout = await AdyenCheckout(adyenConfiguration);

      checkout.submitDetails({ details: { redirectResult: redirectResult } });
    };

    handleRedirect();
  }, [refundOption]);

  return (
    <>
      <div style={{ width: "90%", marginTop: "3vh", marginBottom: "3vh", marginLeft: "auto", marginRight: "auto" }}>
        <Card variant="outlined">
          <CardContent>
            <div style={{ textAlign: "center" }}>
              <Typography variant="h4">{message}</Typography>
            </div>
            <Grid
              container
              direction="row"
              justifyContent="space-around"
              alignItems="center"
              style={{ marginTop: "2vh" }}
            >
              <Grid item>
                <CircularProgress />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </div>
    </>
  );
}
