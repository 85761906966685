import { Block, Forward } from "@mui/icons-material";
import { Grid, Typography } from "@mui/material";
import React from "react";
import Cart from "./Cart";
import OriginalInvoice from "./OriginalInvoice";
import strings from "../localization/localizedStrings";
import { ReturnContext } from "../contexts/ReturnContext";

export default function PageBar(props) {
  const { activeStep, disableUI, config, returnMode } = React.useContext(ReturnContext);
  return (
    <div style={{ height: "50px" }}>
      <div
        style={{
          position: "fixed",
          backgroundColor: "#333333",
          width: "100%",
          height: "55px",
          top: "100px",
          paddingTop: "16px",
          left: 0,
          zIndex: 1,
          pointerEvents: "auto",
        }}
      >
        <Grid container direction="row" justifyContent="space-between" alignItems="center">
          {/* <Grid
            item
            style={{ paddingLeft: "10px", pointerEvents: "auto", cursor: "pointer" }}
            onClick={() => {
              props.previousStep();
            }}
          >
            {!disableUI && <Forward style={{ color: "white", transform: "rotate(180deg)", pointerEvents: "auto" }} />}
            {disableUI && <Block style={{ color: "white", pointerEvents: "auto" }} />}
          </Grid> */}
          <Grid item style={{ paddingLeft: "4%", pointerEvents: "auto" }}>
            <OriginalInvoice />
          </Grid>
          <Grid item xs style={{ textAlign: "center" }}>
            {activeStep === 1 && (
              <Typography variant="body2" color={"HighlightText"}>
                {strings.returnStep1.pagebarText}
              </Typography>
            )}
            {activeStep === 3 && (
              <Typography variant="body2" color={"white"}>
                {strings.returnStep3.pagebarText}
              </Typography>
            )}
          </Grid>
          <Grid item style={{ paddingRight: "4%", pointerEvents: "auto" }}>
            {config.enableReplacementStore && returnMode === "return" && activeStep === 2 && <Cart />}
          </Grid>

          {/* <Grid
            item
            visibility={props.nextStep ? "visible" : "hidden"}
            style={{ paddingRight: "10px", pointerEvents: "auto", cursor: disableUI ? "progress" : "pointer" }}
            onClick={() => {
              props.nextStep();
            }}
          >
            {!disableUI && <Forward style={{ color: "white", pointerEvents: "auto" }} />}
            {disableUI && <Block style={{ color: "white", pointerEvents: "auto" }} />}
          </Grid> */}
        </Grid>
      </div>
    </div>
  );
}
