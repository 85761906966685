import { Button, Card, CardContent, CircularProgress, Dialog, DialogContent, DialogContentText, DialogTitle, Grid, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useMemo } from "react";
import { useHistory } from "react-router-dom";
import { ReturnContext } from "../contexts/ReturnContext";
import noimg from "../assets/noimg.png";
import CurrencyFormat from "../components/Product/CurrencyFormat";
import PageBar from "../components/PageBar";
import useWindowDimensions from "../hooks/useWindowDimensions";
import strings from "../localization/localizedStrings";
import AdyenCheckout from "@adyen/adyen-web";
import "@adyen/adyen-web/dist/adyen.css";
import { createPaymentSession } from "../services/cartService";
import { CardGiftcard, Delete } from "@mui/icons-material";
import { getCheckoutKey, getCheckoutText, getTermsText, validateReturnRequest } from "../services/returnService";
import { useQuery } from "@tanstack/react-query";
import BottomBar from "../components/BottomBar";
import { storeSessionObject } from "../services/storageService";

export default function ConfirmPage(props) {
  const { config, invoice, replacementItems, setActiveStep, setErrorState, createReturn, addGiftcardRefund, disableUI, returnMode, removeReplacementItem, removeReturnItem, returnConf } = React.useContext(ReturnContext);
  const [termsAccepted, setTermsAccepted] = React.useState(false);
  const [termsOpen, setTermsOpen] = React.useState(false);
  const [refundOption, setRefundOption] = React.useState(config.useVoucherDiscount && returnMode === "return" ? -1 : 0);
  const [paymentActive, setPaymentActive] = React.useState(false);

  const { width } = useWindowDimensions();
  const history = useHistory();

  const termsTextQuery = useQuery({
    queryHash: invoice.header?.orderNo,
    queryKey: ["termsTextQuery"],
    queryFn: async () => getTermsText(invoice.header?.orderNo),
    refetchOnWindowFocus: false,
    staleTime: Infinity,
  });

  const checkoutTextQuery = useQuery({
    queryHash: `checkoutTextQuery${invoice.header?.orderNo}`,
    queryKey: ["checkoutTextQuery"],
    queryFn: async () => getCheckoutText(invoice.header?.orderNo),
    refetchOnWindowFocus: false,
    staleTime: Infinity,
  });

  const useStyles = makeStyles({
    pulse: {
      boxShadow: "0 0 0 0 rgba(0, 0, 0, 1)",
      transform: "scale(1)",
      animation: "$pulse 2s infinite",
    },
    "@keyframes pulse": {
      "0%": {
        transform: "scale(0.95)",
        boxShadow: "0 0 0 0 rgba(0, 0, 0, 0.7)",
      },
      "70%": {
        transform: "scale(1)",
        boxShadow: "0 0 0 10px rgba(0, 0, 0, 0)",
      },
      "100%": {
        transform: "scale(0.95)",
        boxShadow: "0 0 0 0 rgba(0, 0, 0, 0)",
      },
    },
  });

  const classes = useStyles();

  // React.useEffect(() => {
  //   console.log(config);
  // }, [config]);

  React.useEffect(() => {
    setActiveStep(3);
  }, [setActiveStep]);

  const back = () => {
    if (config.enableReplacementStore && returnMode === "return") history.push("/replace");
    else history.push("/select");
  };

  const returnLines = replacementItems.lines.filter((f) => f.quantity < 0);
  const replacementLines = replacementItems.lines.filter((f) => f.quantity > 0);

  const defaultRefundDescription = useMemo(() => {
    return invoice.payments[0]?.paymentMethodName ?? "Missing original payment option";
  }, [invoice]);

  const returnAmount = useMemo(() => {
    return returnLines.reduce((amount, line) => amount + -line.amountIncludingVAT, 0.0) - replacementItems.freightFeeDiscountet;
  }, [returnLines, replacementItems.freightFee]);

  const returnDisplayAmount = useMemo(() => {
    return returnLines.reduce((amount, line) => amount + -line.amountIncludingVAT, 0.0);
  }, [returnLines]);

  const replacementAmount = useMemo(() => {
    return replacementLines.reduce((amount, line) => amount + line.amountIncludingVAT, 0.0);
  }, [replacementLines]);

  const balanceAmount = useMemo(() => {
    return Math.round(returnAmount - replacementAmount, 0);
  }, [returnAmount, replacementAmount]);

  const specialAmount = useMemo(() => {
    const changeFactor = 1 + config.voucherBonusDiscount / 100;
    return balanceAmount * changeFactor - ((balanceAmount * changeFactor) % 1);
  }, [balanceAmount, config.voucherBonusDiscount]);

  const refundPayments = useMemo(() => {
    if (invoice?.payments === null || invoice?.payments.length === 0) return [];
    let refundArray = [];
    let refundAmount = balanceAmount;
    let i = 0;
    while (i < invoice.payments.length) {
      if (refundAmount <= 0) break;
      refundArray.push({ paymentMethodName: invoice.payments[i].paymentMethodName, amount: invoice.payments[i].amount > refundAmount ? refundAmount : invoice.payments[i].amount });
      refundAmount -= invoice.payments[i].amount;
      i++;
    }
    return refundArray;
  }, [balanceAmount]);

  React.useEffect(() => {
    if (returnLines.length === 0) history.push("/select");
  }, [returnLines, history]);

  const validateCustomerReturn = async () => {
    try {
      await validateReturnRequest(replacementItems?.cartNumber);
      return true;
    } catch (error) {
      console.error(error);
      setErrorState({
        hasError: true,
        errorMsg: strings.returnStep3.errorValidation + "( " + error.response.data + " )",
      });
      return false;
    }
  };

  const persistStep = async (checkoutResultCode, checkoutSessionData, checkoutSessionResult) => {
    // if (refundOption === -1) {
    //   setErrorState({ hasError: true, errorMsg: strings.returnStep3.errorRefundOption, severity: "warning" });
    //   return;
    // }
    if (!termsAccepted) {
      setErrorState({ hasError: true, errorMsg: strings.returnStep3.errorAgreement, severity: "warning" });
      return;
    }
    const validated = await validateCustomerReturn();
    if (!validated) return;

    await callCreateReturn(refundOption === -1 ? 0 : refundOption, checkoutResultCode, checkoutSessionData, checkoutSessionResult);
  };

  const callCreateReturn = async (rOption, checkoutResultCode, checkoutSessionData, checkoutSessionResult) => {
    storeSessionObject("checkoutOrderNo", invoice.header?.orderNo);
    storeSessionObject("checkoutEmail", invoice.header?.email);
    storeSessionObject("checkoutReturnMode", returnMode);
    storeSessionObject("checkoutCartNo", replacementItems?.cartNumber);
    await createReturn(rOption, checkoutResultCode, checkoutSessionData, checkoutSessionResult);
  };

  /* https://docs.adyen.com/online-payments/web-drop-in */

  const startPaymentSession = async () => {
    if (balanceAmount >= 0) {
      setErrorState({ hasError: true, errorMsg: "There's nothing to pay" });
      return;
    }
    if (!termsAccepted) {
      setErrorState({ hasError: true, errorMsg: strings.returnStep3.errorAgreement, severity: "warning" });
      return;
    }

    const validated = await validateCustomerReturn();
    if (!validated) return;

    const key = await getCheckoutKey(invoice.header?.orderNo);
    const session = await createPaymentSession(replacementItems?.cartNumber);

    storeSessionObject("checkoutReturnMode", returnMode);
    storeSessionObject("adyenCheckoutKey", key);
    storeSessionObject("adyenCheckoutSession", session);
    storeSessionObject("adyenCheckoutRefundoption", refundOption);
    storeSessionObject("returnConf", returnConf);
    storeSessionObject("replacementItems", replacementItems);

    const adyenConfiguration = {
      environment: session.environment,
      clientKey: key,
      analytics: {
        enabled: true,
      },
      session: {
        id: session.sessionId,
        sessionData: session.sessionData,
      },
      onPaymentCompleted: async (result, component) => {
        if (result.resultCode !== "Authorised") {
          setErrorState({ hasError: true, errorMsg: "Payment could not be authorized", severity: "warning" });
          return;
        }
        await callCreateReturn(refundOption, result.resultCode, result.sessionData, result.sessionResult);
      },
      onError: (error, component) => {
        console.error(error);
        console.error(component);
        setErrorState({ hasError: true, errorMsg: error.message });
      },
    };

    const checkout = await AdyenCheckout(adyenConfiguration);

    checkout.create("dropin").mount("#dropin-adyen-container");
  };

  const RefundOffer = () => {
    if (balanceAmount <= 0 || !config.useVoucherDiscount || returnMode === "claim") return <></>;

    if (refundOption === 0)
      return (
        <div style={{ height: 35 }}>
          <div
            style={{
              position: "fixed",
              width: "100%",
              height: "75px",
              top: 150,
              left: 0,
              zIndex: 10,
              cursor: "pointer",
            }}
          >
            <Grid container spacing={1} justifyContent={"center"} style={{ backgroundColor: "black", color: "white" }}>
              <Grid item>
                <div style={{ textAlign: "center" }} onClick={() => setRefundOption(-1)}>
                  <Typography variant="h5">{strings.returnStep3.refundOfferMini}!</Typography>
                </div>
              </Grid>
            </Grid>
          </div>
        </div>
      );

    return (
      <div style={{ width: "90%", marginTop: "3vh", marginBottom: "3vh", marginLeft: "auto", marginRight: "auto" }}>
        <Card variant="outlined">
          <CardContent>
            <div style={{ textAlign: "center" }}>
              <Typography variant="h4">{strings.returnStep3.refundOfferHeader}</Typography>
              <Typography variant="overline">{strings.returnStep3.refundOfferText.replace("{0}", balanceAmount.toFixed(2)).replace("{1}", specialAmount.toFixed(2))}</Typography>
            </div>
            <Grid container direction="row" justifyContent="space-around" alignItems="center" style={{ marginTop: "2vh" }}>
              {disableUI && (
                <Grid item>
                  <CircularProgress />
                </Grid>
              )}
              {!disableUI && (
                <>
                  <Grid item>
                    <Button variant="outlined" onClick={() => setRefundOption(0)} sx={{ fontFamily: "Bebas Neue", fontSize: "1.1rem" }}>
                      {strings.returnStep3.refundOfferNoThanks}
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      variant="contained"
                      onClick={async () => {
                        await addGiftcardRefund();
                        setRefundOption(1);
                      }}
                      sx={{ fontFamily: "Bebas Neue", fontSize: "1.23rem" }}
                      className={classes.pulse}
                    >
                      {strings.returnStep3.refundOfferYesPlease}
                    </Button>
                  </Grid>
                </>
              )}
            </Grid>
          </CardContent>
        </Card>
      </div>
    );
  };

  const TermsAndConditions = () => {
    return (
      <Dialog open={termsOpen} onClose={() => setTermsOpen(false)} scroll={"paper"}>
        <DialogTitle>Terms & Conditions</DialogTitle>
        <DialogContent dividers>
          <DialogContentText>
            <span dangerouslySetInnerHTML={{ __html: termsTextQuery.data }} />
          </DialogContentText>
        </DialogContent>
      </Dialog>
    );
  };

  return (
    <div>
      <PageBar width={width} previousStep={() => back()} />
      <div style={{ width: "95vw", maxWidth: "1200px", marginLeft: "auto", marginRight: "auto" }}>
        <RefundOffer />
        <Grid container direction="row" justifyContent="center" alignItems="center" style={{ marginTop: 15, marginBottom: 15, width: "100%" }}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Card>
              <CardContent>
                {/* {returnMode === "return" && (
                  <Typography variant="h5">{strings.returnStep3.headerReturnItems}</Typography>
                )}
                {returnMode !== "return" && <Typography variant="h5">{strings.returnStep3.headeClaimItems}</Typography>} */}
                <Typography variant="body2">
                  <span dangerouslySetInnerHTML={{ __html: checkoutTextQuery?.data ?? "" }} />
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
        <div style={{ marginTop: "2vh" }}>
          <Grid container direction="row" justifyContent="space-between" alignItems="center" style={{ marginBottom: "1vh" }}>
            <Grid item xs>
              {returnMode === "return" && <Typography variant="h5">{strings.returnStep3.headerReturnItems}</Typography>}
              {returnMode !== "return" && <Typography variant="h5">{strings.returnStep3.headeClaimItems}</Typography>}
            </Grid>
            <Grid item></Grid>
          </Grid>
          {returnLines.map((line, index) => {
            const invoiceLine = invoice.lines.find((f) => f.id === line.fromInvoiceLineId);
            return (
              <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start" key={index}>
                <Grid item>
                  <img
                    style={{ width: "12vh", height: "50%", maxHeight: "50%", objectFit: "cover" }}
                    src={invoiceLine.imageUrl ? invoiceLine.imageUrl : noimg}
                    onError={(e) => {
                      e.target.onerror = null;
                      e.target.src = noimg;
                    }}
                    alt={invoiceLine.description}
                  />
                </Grid>
                <Grid item xs style={{ marginLeft: "2vh" }}>
                  <Grid container>
                    <Grid item>
                      <Delete
                        fontSize="large"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          if (window.confirm("Are you sure you want to remove this item?")) {
                            removeReturnItem(line.id);
                          }
                        }}
                      />
                    </Grid>
                    <Grid item xs>
                      <Typography variant="h6">{invoiceLine.description}</Typography>
                    </Grid>
                  </Grid>
                  <Typography variant="caption">
                    {invoiceLine.colorDesc} {invoiceLine.sizeDesc}
                    {invoiceLine.lengthDesc}
                  </Typography>
                  <Typography variant="body2">
                    <CurrencyFormat
                      value={invoiceLine.type === 1 ? invoiceLine.unitPrice : invoiceLine.amountIncludingVAT}
                      renderText={(formattedValue) => {
                        return (
                          <span>
                            {invoice.header.currencyCode} {formattedValue}
                          </span>
                        );
                      }}
                    />
                  </Typography>
                  <div style={{ marginTop: "1vh" }}>
                    <Typography>{line.returnReasonDescription}</Typography>
                    <Typography variant="caption">{line.comment}</Typography>
                  </div>
                </Grid>
              </Grid>
            );
          })}
          <Grid container direction="row" justifyContent="space-between" alignItems="center">
            <Grid item xs></Grid>
            <Grid item>
              <Typography variant="h6">
                <CurrencyFormat
                  value={returnDisplayAmount}
                  renderText={(formattedValue) => {
                    return (
                      <span>
                        {invoice.header.currencyCode} {formattedValue}
                      </span>
                    );
                  }}
                />
              </Typography>
            </Grid>
          </Grid>
          <hr />
        </div>
        {replacementItems.freightFee > 0 && (
          <div style={{ marginTop: "2vh" }}>
            <Grid container direction="row" justifyContent="space-between" alignItems="center">
              <Grid item xs>
                <Typography variant="h5">{strings.returnFreight}</Typography>
              </Grid>
              <Grid item>
                <Typography variant="h6">
                  <CurrencyFormat
                    value={replacementItems.freightFeeDiscountet}
                    renderText={(formattedValue) => {
                      if (replacementItems.freightFeeDiscountet < replacementItems.freightFee) {
                        return (
                          <span>
                            - {invoice.header.currencyCode} <span style={{ textDecoration: "line-through" }}>{replacementItems.freightFee}</span> <span style={{ fontWeight: "bold", color: "red" }}>{formattedValue}</span>
                          </span>
                        );
                      } else {
                        return (
                          <span>
                            - {invoice.header.currencyCode} {formattedValue}
                          </span>
                        );
                      }
                    }}
                  />
                </Typography>
              </Grid>
            </Grid>
            <hr />
          </div>
        )}
        {replacementLines.length > 0 && (
          <div style={{ marginTop: "2vh" }}>
            <Grid container direction="row" justifyContent="space-between" alignItems="center" style={{ marginBottom: "1vh" }}>
              <Grid item>
                <Typography variant="h5">{strings.returnStep3.headerReplacementItems}</Typography>
              </Grid>
              <Grid item></Grid>
            </Grid>
            {replacementLines.map((item, index) => {
              return (
                <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start" key={index}>
                  <Grid item>
                    {item.type === 1 && (
                      <div
                        style={{
                          width: "12vh",
                          height: "50%",
                          maxHeight: "50%",
                          objectFit: "cover",
                          textAlign: "center",
                        }}
                      >
                        <CardGiftcard sx={{ fontSize: "8rem" }} />
                      </div>
                    )}
                    {item.type === 2 && (
                      <img
                        style={{ width: "12vh", height: "50%", maxHeight: "50%", objectFit: "cover" }}
                        src={item.imageUrl ? item.imageUrl : noimg}
                        onError={(e) => {
                          e.target.onerror = null;
                          e.target.src = noimg;
                        }}
                        alt={item.description}
                      />
                    )}
                  </Grid>
                  <Grid item xs style={{ marginLeft: "2vh" }}>
                    <Grid container>
                      <Grid item>
                        <Delete
                          fontSize="large"
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            if (window.confirm("Are you sure you want to remove this item?")) {
                              removeReplacementItem(item.id);
                            }
                          }}
                        />
                      </Grid>
                      <Grid item xs>
                        <Typography variant="h6">{item.description}</Typography>
                      </Grid>
                    </Grid>
                    <Typography variant="caption">
                      {item.colorDescription} {item.sizeDescription}
                      {item.lengthDescription}
                    </Typography>
                    <Typography variant="body2">
                      <CurrencyFormat value={item.type === 1 ? item.unitPrice : item.amountIncludingVAT} />
                    </Typography>
                  </Grid>
                </Grid>
              );
            })}
            <Grid container direction="row" justifyContent="space-between" alignItems="center">
              <Grid item></Grid>
              <Grid item>
                <Typography variant="h6">
                  <CurrencyFormat
                    value={replacementAmount}
                    renderText={(formattedValue) => {
                      return (
                        <span>
                          - {invoice.header.currencyCode} {formattedValue}
                        </span>
                      );
                    }}
                  />
                </Typography>
              </Grid>
            </Grid>
            <hr />
          </div>
        )}
        {balanceAmount !== 0 && (
          <div style={{ marginTop: "2vh" }}>
            <Grid container direction="row" justifyContent="space-between" alignItems="center">
              <Grid item>
                {balanceAmount <= 0 && <Typography variant="h5">{strings.returnStep3.headerToPay}</Typography>}
                {balanceAmount > 0 && <Typography variant="h5">{strings.returnStep3.headerToRefund}</Typography>}
              </Grid>
              <Grid item>
                <Typography variant="h6">
                  <CurrencyFormat
                    value={Math.abs(balanceAmount)}
                    renderText={(formattedValue) => {
                      return (
                        <span>
                          {invoice.header.currencyCode} {formattedValue}
                        </span>
                      );
                    }}
                  />
                </Typography>
              </Grid>
            </Grid>
            <hr />
            {balanceAmount > 0 && (
              <Typography variant="overline">
                {refundOption === -1 && <span>{strings.returnStep3.refundOptionNotSet} </span>}
                {refundOption === 0 && (
                  <>
                    {refundPayments?.map((p) => {
                      return (
                        <Grid container direction="row" justifyContent="space-between" alignItems="center">
                          <Grid item>{p?.paymentMethodName} </Grid>
                          <Grid item>
                            <CurrencyFormat
                              value={p?.amount}
                              renderText={(formattedValue) => {
                                return <span>{formattedValue}</span>;
                              }}
                            />
                          </Grid>
                        </Grid>
                      );
                    })}
                  </>
                )}
                {refundOption === 1 && (
                  <span style={{ fontWeight: "bold" }}>
                    {strings.returnStep3.refundVoucer}{" "}
                    <CurrencyFormat
                      value={specialAmount}
                      renderText={(formattedValue) => {
                        return <span>{formattedValue}</span>;
                      }}
                    />
                  </span>
                )}
              </Typography>
            )}
          </div>
        )}

        <div style={{ marginBottom: "10vh" }}>
          <div id="dropin-adyen-container"></div>
        </div>

        {!paymentActive && (
          <div style={{ marginTop: "3vh", marginBottom: "5vh", width: "40vh", marginLeft: "auto", marginRight: "auto" }}>
            <div>
              <input
                type="checkbox"
                name={"cbp"}
                id="cbp"
                onClick={(e) => {
                  setTermsAccepted(e.target.checked);
                }}
              />{" "}
              <label htmlFor={`cbp`}> {strings.returnStep3.termsAndConditions}</label>{" "}
              <Typography
                component={"span"}
                sx={{ cursor: "pointer" }}
                onClick={() => {
                  setTermsOpen(true);
                }}
              >
                ({strings.returnStep3.readTerms})
              </Typography>
            </div>
            <br />
            {balanceAmount >= 0 && (
              <Button
                variant="contained"
                fullWidth
                disabled={!termsAccepted || disableUI}
                onClick={async () => {
                  await persistStep();
                }}
              >
                {disableUI && (
                  <span>
                    <CircularProgress size={16} sx={{ color: "black", fontSize: "0.5rem" }} />
                  </span>
                )}
                {!disableUI && returnMode === "return" && <span>{strings.returnStep3.agreeButtonReturn}</span>}
                {!disableUI && returnMode === "claim" && <span>{strings.returnStep3.agreeButtonClaim}</span>}
              </Button>
            )}
            {balanceAmount < 0 && (
              <Button
                variant="contained"
                fullWidth
                disabled={!termsAccepted}
                onClick={async () => {
                  setRefundOption(0);
                  try {
                    setPaymentActive(true);
                    await startPaymentSession();

                    window.setTimeout(() => {
                      window.scrollTo({
                        top: window.document.documentElement.scrollHeight,
                        behavior: "smooth",
                      });
                    }, 2000);
                  } catch (error) {
                    setPaymentActive(false);
                    setErrorState({ hasError: true, error: "Could not initialize checkout" });
                  } finally {
                  }
                }}
              >
                {strings.returnStep3.paymentButton}
              </Button>
            )}
          </div>
        )}
      </div>
      <TermsAndConditions />
      <BottomBar previousStep={() => back()} />
    </div>
  );
}
