import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import { getLatestProducts } from "../../services/productService";
import noimg from "../../assets/noimg.png";
import CurrencyFormat from "./CurrencyFormat";
import { useHistory } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import strings from "../../localization/localizedStrings";

const LatestProducts = () => {
  const latestProductsQuery = useQuery({
    queryKey: ["latestProducts"],
    queryFn: async () => getLatestProducts(),
    refetchOnWindowFocus: false,
    staleTime: Infinity,
  });
  const history = useHistory();
  if (latestProductsQuery.isLoading) {
    return <p>Loading...</p>;
  }

  if (latestProductsQuery.isError) {
    return <p>Error: {latestProductsQuery.error.message}</p>;
  }

  if (latestProductsQuery.data?.length === 0) return null;

  return (
    <div style={{ marginTop: "50px" }}>
      <Typography variant="h5">{strings.productBrowser.productsNewTitle}</Typography>
      <Grid container spacing={2} style={{ overflowX: "auto", overflowY: "clip" }}>
        <Grid item xs={12}>
          <Box display="flex" pb={2}>
            {latestProductsQuery.data?.map((product, index) => (
              <Box
                key={index}
                flexShrink={0}
                mr={2}
                style={{
                  display: "grid",
                  gridTemplateColumns: "repeat(auto-fit, minmax(180px, 0.5fr))",
                  gridGap: "5px",
                  backgroundImage:
                    "url('data:image/svg+xml;utf8,<svg width='100%' height='80%' viewBox='0 0 100 100' xmlns='http://www.w3.org/2000/svg'><text x='50%' y='50%' style='font-family: sans-serif; font-size: 12px;' dominant-baseline='middle' text-anchor='middle'>Loading…</text></svg>')",
                }}
              >
                <div
                  key={index}
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    history.push("/replace/" + product.productIdentifier);
                  }}
                >
                  <img
                    style={{
                      width: "100%",
                      height: "80%",
                      minHeight: "80%",
                      maxHeight: "80%",
                      minWidth: "100%",
                      maxWidth: "100%",
                      objectFit: "cover",
                    }}
                    src={product.imageUrlMedium ? product.imageUrlMedium : noimg}
                    alt={product.productDescription}
                    onError={({ currentTarget }) => {
                      currentTarget.onerror = null; // prevents looping
                      currentTarget.src = noimg;
                    }}
                  />
                  <Typography variant="caption" fontWeight="bold" component={"div"} m={0} p={0}>
                    {product.programDescription.substring(0, 20)}
                  </Typography>
                  <Typography variant="caption" component={"div"} m={0} p={0}>
                    {product.productDescription.substring(0, 20)}
                  </Typography>
                  <CurrencyFormat
                    variant="caption"
                    component={"span"}
                    value={product.productPrice}
                    renderText={(formattedValue) => {
                      <Typography variant="caption" component={"div"} m={0} p={0}>
                        {formattedValue}
                      </Typography>;
                    }}
                  />
                </div>
              </Box>
            ))}
          </Box>
        </Grid>
      </Grid>
    </div>
  );
};

export default LatestProducts;
