import axios from "axios";
import { getPersistedObject, getSessionObject } from "./storageService";

const api = axios.create({
  baseURL: "/",
  headers: {
    "Content-type": "application/json",
  },
});

export const GET = async (url, params) => {
  const { installationId, unit } = getPersistedObject("initParams");
  const { orderNo, shopNo } = getSessionObject("invoice")?.header ?? { orderNo: "", shopNo: "" };
  const response = await api
    .get(url, {
      ...{ params },
      headers: {
        installationId: installationId,
        unit: unit,
        orderNumber: orderNo ?? "",
        shopNumber: shopNo ?? "",
      },
    })
    .then(({ data }) => data);
  return response;
};

export const POST = async (url, body, params, customHeaders) => {
  const { installationId, unit } = getPersistedObject("initParams");
  const { orderNo, shopNo } = getSessionObject("invoice")?.header ?? { orderNo: "", shopNo: "" };
  let useHeaders = {
    ...{
      installationId: installationId,
      unit: unit,
      orderNumber: orderNo ?? "",
      shopNumber: shopNo ?? "",
    },
    ...customHeaders,
  };
  const response = await api.post(url, body, { params, headers: useHeaders }).then(({ data }) => data);
  return response;
};

export const DELETE = async (url, params) => {
  const { installationId, unit } = getPersistedObject("initParams");
  const { orderNo, shopNo } = getSessionObject("invoice")?.header ?? { orderNo: "", shopNo: "" };
  const response = await api
    .delete(url, {
      ...{ params },
      headers: {
        installationId: installationId,
        unit: unit,
        orderNumber: orderNo ?? "",
        shopNumber: shopNo ?? "",
      },
    })
    .then(({ data }) => data);
  return response;
};
