import React from "react";
import { NumericFormat } from "react-number-format";

export default function CurrencyFormat(props) {
  return (
    <NumericFormat
      value={props.value}
      displayType={"text"}
      thousandSeparator={"."}
      decimalSeparator={","}
      decimalScale={2}
      fixedDecimalScale={true}
      //prefix={"$"} Set this to shop currency
      //suffix={" kr"}
      //   renderText={(formattedValue) => {
      //     if (props.renderText) {
      //       props.renderText(formattedValue);
      //     } else {
      //       <span>{formattedValue}</span>;
      //     }
      //   }}
      //   renderText={(formattedValue) => {
      //     console.log(formattedValue);
      //     <div>{formattedValue}</div>;
      //   }}
      renderText={(formattedValue) =>
        typeof props.renderText === "function" ? props.renderText(formattedValue) : <div>{formattedValue}</div>
      }
    ></NumericFormat>
  );
}
