import React from "react";
import { Drawer, Grid, Typography } from "@mui/material";
import strings from "../localization/localizedStrings";
import { Person } from "@mui/icons-material";
import { ReturnContext } from "../contexts/ReturnContext";
import useWindowDimensions from "../hooks/useWindowDimensions";

export default function OriginalInvoice(props) {
  const [expanded, setExpanded] = React.useState(false);
  const { invoice } = React.useContext(ReturnContext);
  const { width } = useWindowDimensions();
  const returnBalance = () => {
    const valueFromLines = invoice?.lines.reduce(
      (refundAmount, line) =>
        refundAmount +
        (line.amountIncludingVAT / line.quantity) * line.quantityToReturn -
        (line.replacementVariant
          ? line.replacementVariant.price < line.amountIncludingVAT
            ? line.amountIncludingVAT
            : line.replacementVariant.price
          : 0.0),
      0.0
    );
    const valueFromReplacements = invoice?.replacementLines
      ? invoice?.replacementLines.reduce((replaceAmount, line) => replaceAmount + line.amountIncludingVAT, 0.0)
      : 0.0;
    return valueFromLines - valueFromReplacements;
  };
  return (
    <>
      <Person
        style={{ color: "white", cursor: "pointer" }}
        onClick={() => {
          setExpanded(true);
        }}
      />
      <Drawer
        anchor="left"
        open={expanded}
        onClose={() => {
          setExpanded(false);
        }}
        PaperProps={{ style: { width: width > 800 ? "45%" : "70%" } }}
      >
        <Grid container direction={"column"} spacing={3} style={{ padding: "20px" }}>
          <Grid item>
            <Typography variant="h5">{strings.orderHeader.header}</Typography>
          </Grid>
          <Grid item>
            <Typography variant="h6">{strings.orderHeader.customer}</Typography>
            <Typography gutterBottom>{invoice?.header?.selltoCustomerName}</Typography>
            <Typography gutterBottom>{invoice?.header?.sellToAddress}</Typography>{" "}
            <Typography gutterBottom>
              {invoice?.header?.sellToZip} {invoice?.header?.sellToCity}{" "}
            </Typography>
            <Typography gutterBottom>{invoice?.header?.sellToCountry}</Typography>
            <Typography gutterBottom>{invoice?.header?.email}</Typography>
          </Grid>
        </Grid>
      </Drawer>
    </>
  );
}
