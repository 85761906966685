import React from "react";
import { Button, Grid } from "@mui/material";
import { ReturnContext } from "../contexts/ReturnContext";
import useWindowDimensions from "../hooks/useWindowDimensions";
import { ChevronLeft, ChevronRight } from "@mui/icons-material";
import strings from "../localization/localizedStrings";

export default function BottomBar(props) {
  const { activeStep, disableUI } = React.useContext(ReturnContext);
  const { width } = useWindowDimensions();
  const cols = width <= 800 ? 12 : width < 1110 ? 12 : 8;

  return (
    <div style={{ marginTop: "80px" }}>
      <div style={{ position: "fixed", width: "100%", height: "auto", bottom: 0, left: 0, zIndex: 10 }}>
        <Grid
          container
          direction="row"
          justifyContent="space-around"
          alignItems="center"
          style={{
            backgroundColor: "#f2f2f2",
            padding: "8px",
            borderTop: "1px solid #dedede",
            borderBottom: "1px solid #dedede",
          }}
        >
          <Grid item xs={0} md={1}></Grid>
          <Grid item>
            <Button
              variant="contained"
              startIcon={<ChevronLeft />}
              disabled={disableUI}
              onClick={() => {
                props.previousStep();
              }}
            >
              {strings.bottomBar.prevStep}
            </Button>
          </Grid>
          <Grid item>
            {props.nextStep && (
              <Button
                variant="contained"
                endIcon={<ChevronRight />}
                disabled={disableUI}
                sx={{ visibility: props.nextStep ? "visible" : "hidden" }}
                onClick={() => {
                  props.nextStep();
                }}
              >
                {strings.bottomBar.nextStep}
              </Button>
            )}
          </Grid>
          <Grid item xs={0} md={1}></Grid>
        </Grid>
      </div>
    </div>
  );
}
