import React, { useEffect } from "react";
import strings from "../localization/localizedStrings";
import { ArrowCircleDown, ArrowCircleRight, ArrowDropDownCircle, ChangeCircle, Check, Clear, Feedback, SwitchAccessShortcut } from "@mui/icons-material";
import { Card, CardContent, Grid, Popover, TextareaAutosize, Button, ToggleButton, ToggleButtonGroup, Typography, TextField, MenuItem } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { reduceVariantsToColorsWithStock, reduceVariantsToLengthsWithStock, reduceVariantsToSizesWithStock } from "../services/variantService";
import { getProductVariants } from "../services/productService";
import { ReturnContext } from "../contexts/ReturnContext";

export default function ReturnChoicesMobile(props) {
  const { returnConf, invoice, returnMode, config, setErrorState } = React.useContext(ReturnContext);
  const variantsQuery = useQuery(["variants", props.line.itemNo], async () => getProductVariants(invoice.header.currencyCode, invoice.header.customerNo, props.line.itemNo), { refetchOnWindowFocus: false, staleTime: 0 });
  const variantReplacementVersion = 2;
  const [pickedColorCode, setPickedColorCode] = React.useState(props.line.colorCode ?? "");
  const [pickedSizeCode, setPickedSizeCode] = React.useState("");
  const [pickedLengthCode, setPickedLengthCode] = React.useState("");
  const [sizeDialog, setSizeDialog] = React.useState({
    open: false,
    x: 0,
    y: 0,
    line: null,
  });

  useEffect(() => {
    setPickedSizeCode("");
    setPickedLengthCode("");
  }, [pickedColorCode]);

  useEffect(() => {
    setPickedLengthCode("");
  }, [pickedSizeCode]);

  const showColors = React.useCallback(() => {
    return variantsQuery.data?.find((f) => f.colorCode !== "") !== undefined;
  }, [variantsQuery.data]);

  const showSizes = React.useCallback(() => {
    return variantsQuery.data?.find((f) => f.sizeCode !== "") !== undefined;
  }, [variantsQuery.data]);

  const showLengths = React.useCallback(() => {
    return variantsQuery.data?.find((f) => f.lengthCode !== "") !== undefined;
  }, [variantsQuery.data]);

  const validVariantCombination = React.useCallback(() => {
    return variantsQuery.data?.find((f) => f.colorCode === pickedColorCode && f.sizeCode === pickedSizeCode && f.lengthCode === pickedLengthCode) !== undefined;
  }, [variantsQuery.data, pickedColorCode, pickedSizeCode, pickedLengthCode]);

  const colors = React.useCallback(() => reduceVariantsToColorsWithStock(variantsQuery.data), [variantsQuery.data]);
  const sizes = React.useCallback(() => reduceVariantsToSizesWithStock(variantsQuery.data, pickedColorCode), [variantsQuery.data, pickedColorCode]);
  const lengths = React.useCallback(() => reduceVariantsToLengthsWithStock(variantsQuery.data, pickedColorCode, pickedSizeCode), [variantsQuery.data, pickedColorCode, pickedSizeCode]);

  const setPickedVariant = (line) => {
    const pickedVariant = variantsQuery.data?.find((f) => f.colorCode === pickedColorCode && f.sizeCode === pickedSizeCode && f.lengthCode === pickedLengthCode);
    if (pickedVariant === undefined || pickedVariant?.stockQty <= 0) {
      setErrorState({ hasError: true, errorMsg: "Invalid color and size combination" });
      return;
    }
    console.log("Picked variant", pickedVariant);
    props.setReplacementVariant(line, pickedVariant);
    closeSize();
    console.log(line);
  };

  const closeSize = () => {
    setSizeDialog({
      open: false,
      x: 0,
      y: 0,
      line: null,
    });
  };

  const returnReasons = React.useCallback(() => {
    if (returnMode === "claim") {
      return props.returnReasons?.filter((f) => f.code !== "" && f.code !== null && f.isClaim);
    } else {
      return props.returnReasons?.filter((f) => f.code !== "" && f.code !== null && f.isReturn);
    }
  }, [returnMode, props.returnReasons]);

  const returnLine = returnConf.returnLines?.find((f) => f.guid === props.line.guid);

  return (
    <>
      {returnLine?.returnChoice === "1" && (
        <Grid container direction="row" justifyContent="center" alignItems="stretch" spacing={2}>
          <Grid item>
            <Grid container direction="row" justifyContent="center" alignItems="center">
              <Grid item xs>
                <ArrowCircleDown fontSize="large" />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}
      <Grid container direction="column" justifyContent="flex-start" alignItems="stretch" spacing={2}>
        {returnLine?.returnChoice === "1" && (
          <>
            <Grid item>
              <Grid container direction="row" justifyContent="center" alignItems="center" style={{ height: "100%" }}>
                <Grid item xs>
                  <ToggleButtonGroup color="primary" value={returnLine?.returnReason ?? ""} exclusive onChange={(e) => {}} aria-label="Platform" fullWidth size="small" orientation="vertical">
                    {returnReasons()?.map((r, index) => {
                      return (
                        <ToggleButton
                          key={index}
                          value={r.code}
                          size="small"
                          style={{ fontSize: 10 }}
                          onClick={(e) => {
                            props.setReturnReason(props.line, r);
                          }}
                        >
                          {r.description}
                        </ToggleButton>
                      );
                    })}
                  </ToggleButtonGroup>
                </Grid>
              </Grid>
            </Grid>
          </>
        )}

        {/* Replacement not possible */}

        {returnLine?.returnChoice === "1" &&
          returnLine?.returnReason &&
          ((variantsQuery.data?.length > 0 && variantsQuery.data?.filter((f) => f.stockQty > 0).length === 0) || (variantsQuery.data?.length === 0 && props.line?.stock === 0)) &&
          returnMode === "return" && (
            <>
              <Grid item xs>
                <Grid container direction="column" justifyContent="center" alignItems="center">
                  <Grid item xs>
                    <ChangeCircle fontSize="large" color="disabled" />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <Grid container direction="row" justifyContent="center" alignItems="center" style={{ height: "100%" }}>
                  <Grid item xs>
                    <Typography variant="button">{strings.returnStep1.optNoChangeNoStock}</Typography>
                  </Grid>
                </Grid>
              </Grid>
            </>
          )}

        {/* Replacement of base item */}

        {returnLine?.returnChoice === "1" && returnLine?.returnReason && variantsQuery.data?.length === 0 && returnMode === "return" && props.line?.stock > 0 && (
          <>
            <Grid item>
              <Grid container direction="column" justifyContent="center" alignItems="center">
                <Grid item xs>
                  <ChangeCircle fontSize="large" />
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Grid container direction="row" justifyContent="center" alignItems="center" style={{ height: "100%" }}>
                <Grid item xs>
                  <ToggleButtonGroup
                    color="primary"
                    value={""}
                    exclusive
                    onChange={(e) => {
                      if (e.target.value === "1") props.setReplacementItem(returnLine, true);
                      if (e.target.value === "0") props.setReplacementItem(returnLine, false);
                    }}
                    aria-label="Platform"
                    fullWidth
                    size="small"
                    orientation="vertical"
                  >
                    <ToggleButton
                      value="1"
                      selected={returnLine?.replacementItem}
                      size="small"
                      style={{ fontSize: 10 }}
                      onChange={() => {
                        //setSelected(!selected);
                        props.setReplacementItem(props.line);
                      }}
                    >
                      {returnLine?.replacementItem && (
                        <>
                          <Check color="success" />
                          {strings.returnStep1.optChangeOther}
                        </>
                      )}
                      {!returnLine?.replacementItem && <>{strings.returnStep1.optChangeOther}?</>}
                    </ToggleButton>
                    <ToggleButton value="0" selected={!returnLine?.replacementItem} size="small" style={{ fontSize: 10 }}>
                      {config.enableReplacementStore ? strings.returnStep1.changeProductWithReplacementStore : strings.returnStep1.changeProductNoReplacementStore}
                    </ToggleButton>
                  </ToggleButtonGroup>
                </Grid>
              </Grid>
            </Grid>
          </>
        )}

        {/* Replacement of variant item */}

        {config.enableVariantExchange === true && returnLine?.returnChoice === "1" && returnLine?.returnReason && variantsQuery.data?.length > 0 && variantsQuery.data?.filter((f) => f.stockQty > 0).length > 0 && returnMode === "return" && (
          <>
            <Grid item>
              <Grid container direction="column" justifyContent="center" alignItems="center">
                <Grid item xs>
                  <ChangeCircle fontSize="large" />
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Grid container direction="row" justifyContent="center" alignItems="center" style={{ height: "100%" }}>
                <Grid item xs>
                  <ToggleButtonGroup
                    color="primary"
                    value={""}
                    aria-label="Platform"
                    fullWidth
                    size="small"
                    orientation="vertical"
                    onChange={(e) => {
                      if (e.target.value === "0") {
                        props.setReplacementVariant(returnLine, null);
                        setSizeDialog({ open: false });
                      }
                    }}
                  >
                    <ToggleButton value="0" selected={!sizeDialog.open && (returnLine?.replacementVariant === undefined || returnLine?.replacementVariant === null)} size="small" style={{ fontSize: 10 }}>
                      {config.enableReplacementStore ? strings.returnStep1.changeProductWithReplacementStore : strings.returnStep1.changeProductNoReplacementStore}
                    </ToggleButton>
                    <ToggleButton
                      value="1"
                      size="small"
                      selected={returnLine?.replacementVariant !== undefined || sizeDialog.open}
                      style={{ fontSize: 10 }}
                      onClick={(e) => {
                        setSizeDialog({
                          open: true,
                          x: e.clientX,
                          y: e.clientY,
                          line: props.line,
                        });
                      }}
                    >
                      {returnLine?.replacementVariant === undefined && <span>{strings.returnStep1.optChangeVariant}?</span>}
                      {returnLine?.replacementVariant !== undefined && (
                        <>
                          <Check color="success" />
                          {strings.returnStep1.optChangeVariant}:{"  "}
                          <span style={{ fontWeight: "bold" }}>
                            {returnLine?.replacementVariant?.colorDesc} {returnLine?.replacementVariant?.sizeDesc} {returnLine?.replacementVariant?.lengthDesc}
                          </span>
                        </>
                      )}
                    </ToggleButton>
                  </ToggleButtonGroup>
                </Grid>
              </Grid>
            </Grid>
            {sizeDialog.open && (
              <>
                <Grid item>
                  <Grid container direction="column" justifyContent="center" alignItems="center">
                    <Grid item xs>
                      <ArrowDropDownCircle fontSize="large" style={{ transform: "rotate(180deg)" }} />
                    </Grid>
                  </Grid>
                </Grid>
                {showColors() && (
                  <Grid item>
                    <TextField
                      //size="small"
                      select
                      fullWidth
                      label={strings.returnStep1.changeToColor}
                      InputLabelProps={{ shrink: true }}
                      value={pickedColorCode}
                      onChange={(e) => {
                        setPickedColorCode(e.target.value);
                      }}
                    >
                      <MenuItem key={"_0"} value={""}>
                        <Grid container>
                          <Grid item xs>
                            <Typography> </Typography>
                          </Grid>
                        </Grid>
                      </MenuItem>
                      {colors()?.map((variant, index) => {
                        return (
                          <MenuItem key={index} value={variant.colorCode}>
                            <Grid container>
                              <Grid item xs>
                                <Typography sx={{ textDecoration: variant.stockQty > 0 ? "dashed" : "line-through" }}>{variant.colorDesc}</Typography>
                              </Grid>
                            </Grid>
                          </MenuItem>
                        );
                      })}
                    </TextField>
                  </Grid>
                )}
                {showSizes() && (
                  <Grid item>
                    <TextField
                      //size="small"
                      select
                      fullWidth
                      label={strings.returnStep1.changeToSize}
                      InputLabelProps={{ shrink: true }}
                      value={pickedSizeCode}
                      onChange={(e) => {
                        setPickedSizeCode(e.target.value);
                      }}
                    >
                      {sizes()?.map((variant, index) => {
                        return (
                          <MenuItem key={index} value={variant.sizeCode}>
                            <Grid container>
                              <Grid item xs>
                                <Typography sx={{ textDecoration: variant.stockQty > 0 ? "dashed" : "line-through" }}>{variant.sizeDesc}</Typography>
                              </Grid>
                            </Grid>
                          </MenuItem>
                        );
                      })}
                    </TextField>
                  </Grid>
                )}
                {showLengths() && (
                  <Grid item>
                    <TextField
                      size="small"
                      select
                      fullWidth
                      label={strings.returnStep1.changeToLength}
                      InputLabelProps={{ shrink: true }}
                      value={pickedLengthCode}
                      onChange={(e) => {
                        setPickedLengthCode(e.target.value);
                      }}
                    >
                      {lengths()?.map((variant, index) => {
                        return (
                          <MenuItem key={index} value={variant.lengthCode}>
                            <Grid container>
                              <Grid item xs>
                                <Typography sx={{ textDecoration: variant.stockQty > 0 ? "dashed" : "line-through" }}>{variant.lengthDesc}</Typography>
                              </Grid>
                            </Grid>
                          </MenuItem>
                        );
                      })}
                    </TextField>
                  </Grid>
                )}
                <Grid item>
                  <Button
                    variant="contained"
                    fullWidth
                    disabled={!validVariantCombination()}
                    onClick={() => {
                      setPickedVariant(sizeDialog.line);
                    }}
                  >
                    <span>{strings.returnStep1.buttonChangeToColorSize}</span>
                  </Button>
                </Grid>
              </>
            )}
          </>
        )}
      </Grid>

      {returnLine?.returnChoice === "1" && returnLine?.returnChoice2 !== null && (
        <Grid container direction="column" justifyContent="center" alignItems="stretch" spacing={1} mt={1}>
          <Grid item>
            <Grid container direction="column" justifyContent="center" alignItems="center" style={{ height: "100%" }}>
              <Grid item xs>
                <Feedback fontSize="large" />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs>
            <Typography variant="caption" component={"p"}>
              {strings.returnStep1.comment}
            </Typography>
            <TextareaAutosize
              onChange={(e) => {
                props.setReturnComment(props.line, e.target.value);
              }}
              maxLength={500}
              value={returnLine?.returnComment ?? ""}
              style={{
                width: "100%",
                height: "70px",
                fontSize: "9pt",
                padding: "5px",
                border: "1px solid rgba(0, 0, 0, 0.12)",
              }}
            />
          </Grid>
        </Grid>
      )}
    </>
  );
}
