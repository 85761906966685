import { DELETE, POST } from "./http";

export const createCart = async (returnModel) => {
  const url = "return/createCart";
  return await POST(url, returnModel);
};

export const addItem = async (cartNumber, itemNumber, variantId) => {
  const url = "cart/addItem";
  return await POST(url, {
    cartNumber: cartNumber,
    itemNumber: itemNumber,
    variantId: variantId,
    quantity: 1,
  });
};

export const editItemQtyInc = async (cartNumber, lineId) => {
  const url = "cart/iteminc";
  return await POST(url, {
    cartNumber: cartNumber,
    lineId: lineId,
  });
};

export const editItemQtyDec = async (cartNumber, lineId) => {
  const url = "cart/itemdec";
  return await POST(url, {
    cartNumber: cartNumber,
    lineId: lineId,
  });
};

export const removeItem = async (cartNumber, id) => {
  const url = "cart/removeItem";
  const params = new URLSearchParams([
    ["cartNumber", cartNumber],
    ["id", id],
  ]);
  return await DELETE(url, params);
};

export const createPaymentSession = async (cartNumber) => {
  const url = "cart/createPaymentSession";
  const params = new URLSearchParams([["cartNumber", cartNumber]]);
  return await POST(url, {}, params);
};

export const giftcardRefund = async (cartNumber) => {
  const url = "return/addGiftcardRefund";
  const params = new URLSearchParams([["cartNumber", cartNumber]]);
  return await POST(url, {}, params);
};
