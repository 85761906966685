import React, { useContext } from "react";
import strings from "../localization/localizedStrings";
import { Button, CircularProgress, Grid, TextField, Typography } from "@mui/material";
import { ReturnContext } from "../contexts/ReturnContext";
import { getOriginalInvoice, getWelcomeText } from "../services/returnService";
import { useQuery } from "@tanstack/react-query";
import { useHistory } from "react-router-dom";

export default function LoginPage(props) {
  const [login, setLogin] = React.useState({ orderNumber: "", email: "" });
  const [invoiceFound, setInvoiceFound] = React.useState(false);
  const { tokenParams, setInvoice, setErrorState, clearState } = useContext(ReturnContext);
  const history = useHistory();
  const invoiceQuery = useQuery({
    queryKey: ["invoiceQuery"],
    queryFn: async () => getOriginalInvoice(login.orderNumber, login.email),
    queryHash: `${login.orderNumber}${login.email}`,
    enabled: false,
    refetchOnWindowFocus: false,
    staleTime: Infinity,
    retry: 0,
    onSuccess: (data) => {
      setInvoice(data);
      setInvoiceFound(true);
    },
    onError: (error) => {
      clearState();
      setErrorState({
        hasError: true,
        errorMsg: `${error.code} ${error.response.data}`,
      });
      setInvoiceFound(false);
    },
  });

  const welcomeTextQuery = useQuery({
    queryKey: ["welcomeTextQuery"],
    queryFn: async () => getWelcomeText(login.orderNumber),
    queryHash: `welcomeTextQuery`,
    enabled: true,
    refetchOnWindowFocus: false,
    staleTime: Infinity,
    retry: 0,
  });

  React.useEffect(() => {
    if (invoiceFound === false) return;
    history.push("/returnMode");
  }, [invoiceFound, history]);

  React.useEffect(() => {
    if (!tokenParams.fromQuery) return;
    setLogin({ orderNumber: tokenParams.orderNumber, email: tokenParams.email });
  }, [tokenParams, invoiceQuery.refetch]);

  const post = () => {
    invoiceQuery.refetch();
  };

  const welcomeTextHack = welcomeTextQuery.data;

  return (
    <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", marginTop: "2%", marginLeft: 10, marginRight: 10 }}>
      <Grid container direction="row" justifyContent="center" alignItems="center" style={{ marginBottom: "15px" }}>
        <Grid item xs={12} md={5} xl={4}>
          <span dangerouslySetInnerHTML={{ __html: welcomeTextHack }} />
        </Grid>
      </Grid>
      <Grid container justifyContent="center" alignItems="stretch">
        <Grid item></Grid>
        <Grid item xs={12} md={5} xl={4}>
          <Grid container direction="column" justifyContent="center" alignItems="stretch" spacing={2}>
            <Grid item xs>
              <TextField
                size="small"
                fullWidth
                disabled={invoiceQuery.isFetching}
                required
                value={login.orderNumber}
                onChange={(e) => {
                  setLogin((p) => {
                    return { ...p, orderNumber: e.target.value };
                  });
                }}
                label={strings.orderNumber}
                autoFocus
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item xs>
              <TextField
                size="small"
                fullWidth
                disabled={invoiceQuery.isFetching}
                required
                name="Email"
                label={strings.email}
                value={login.email}
                onChange={(e) => {
                  setLogin((p) => {
                    return { ...p, email: e.target.value };
                  });
                }}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    if (invoiceQuery.isFetching) return;
                    post();
                  }
                }}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item xs>
              <Button fullWidth disabled={invoiceQuery.isFetching} variant="contained" onClick={(e) => post()}>
                {strings.pageLanding.buttonText}
              </Button>
            </Grid>
            {invoiceQuery.isFetching && (
              <>
                <Grid item xs style={{ textAlign: "center" }}>
                  <CircularProgress color="primary" />
                </Grid>
                <Grid item xs style={{ textAlign: "center" }}>
                  <Typography>{strings.pageLanding.loadingReturn}</Typography>
                </Grid>
              </>
            )}
          </Grid>
          <Grid item></Grid>
        </Grid>
      </Grid>
    </div>
  );
}
