export const reduceVariantsToColorsWithStock = (variants) => {
  if (!variants) return [];

  const stockValues = variants.reduce((acc, item) => {
    if (acc[item.colorCode] && acc[item.colorCode] !== "") {
      acc[item.colorCode] += item.stockQty ?? item.remainingQuantity;
    } else {
      acc[item.colorCode] = item.stockQty ?? item.remainingQuantity;
    }
    return acc;
  }, {});

  const capturedArray = variants.filter((f) => f);

  return Object.entries(stockValues).map(([colorCode, stockQty]) => {
    const colorItem = capturedArray.find((f) => f.colorCode === colorCode);
    return {
      colorCode,
      stockQty,
      colorDesc: colorItem?.colorDesc ?? colorItem?.colorDescription,
    };
  });
};

export const reduceVariantsToSizesWithStock = (variants, pickedColorCode) => {
  if (!variants) return [];
  const filteredSizes = variants.filter((f) => f.colorCode === pickedColorCode);

  const stockValues = filteredSizes?.reduce((acc, item) => {
    if (acc[item.sizeCode]) {
      acc[item.sizeCode] += item.stockQty ?? item.remainingQuantity;
    } else {
      acc[item.sizeCode] = item.stockQty ?? item.remainingQuantity;
    }
    return acc;
  }, {});

  if (!stockValues) return [];

  const capturedArray = variants.filter((f) => f);

  return Object.entries(stockValues).map(([sizeCode, stockQty]) => {
    const sizeItem = capturedArray.find((f) => f.sizeCode === sizeCode);
    return {
      sizeCode,
      stockQty,
      sizeDesc: sizeItem?.sizeDesc ?? sizeItem?.sizeDescription,
    };
  });
};

export const reduceVariantsToLengthsWithStock = (variants, pickedColorCode, pickedSizeCode) => {
  if (!variants) return [];
  const filteredLengths = variants?.filter((f) => f.colorCode === pickedColorCode && f.sizeCode === pickedSizeCode);

  const stockValues = filteredLengths?.reduce((acc, item) => {
    if (acc[item.lengthCode]) {
      acc[item.lengthCode] += item.stockQty ?? item.remainingQuantity;
    } else {
      acc[item.lengthCode] = item.stockQty ?? item.remainingQuantity;
    }
    return acc;
  }, {});

  if (!stockValues) return [];

  const capturedArray = variants.filter((f) => f);

  return Object.entries(stockValues).map(([lengthCode, stockQty]) => {
    const lengthItem = capturedArray.find((f) => f.lengthCode === lengthCode);
    return {
      lengthCode,
      stockQty,
      lengthDesc: lengthItem?.lengthDesc ?? lengthItem?.lengthDescription,
    };
  });
};
