export function storePersistedObject(key, value) {
  window.localStorage.setItem(key, JSON.stringify(value));
}

export function getPersistedObject(key) {
  var obj = window.localStorage.getItem(key);
  if (obj === null) return null;
  return JSON.parse(obj);
}

export function clearStorage() {
  window.localStorage.clear();
}

export function storeSessionObject(key, value) {
  window.sessionStorage.setItem(key, JSON.stringify(value));
}

export function getSessionObject(key) {
  var obj = window.sessionStorage.getItem(key);
  if (obj === null) return null;
  return JSON.parse(obj);
}

export function clearSession() {
  window.sessionStorage.clear();
}

export function clearAll() {
  clearSession();
  clearStorage();
}
