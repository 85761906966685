import { GET } from "./http";

const getWithResultHandling = async (url, appendParams) => {
  const params = new URLSearchParams([]);
  if (appendParams) params.append(appendParams);
  return (await GET(url, params)).results;
};

const getWithResultAndBlockHandling = async (url, appendParams) => {
  const result = await getWithResultHandling(url, appendParams);
  return result?.filter((f) => f.blocked === false);
};

const getWithBlockHandling = async (url, appendParams) => {
  const params = new URLSearchParams([]);
  if (appendParams) params.append(appendParams);
  return (await GET(url, params)).filter((f) => f.blocked !== true);
};

export const getProductVariants = async (currencyCode, customerNo, itemNo) => {
  const url = "product/getProductVariants";
  const params = new URLSearchParams([
    ["currencyCode", currencyCode],
    ["itemNo", itemNo],
    ["customerNo", customerNo],
  ]);
  return await GET(url, params);
};

export const getPrograms = async () => {
  const url = "product/getPrograms";
  return await getWithResultAndBlockHandling(url);
};

export const getItemGroups = async () => {
  const url = "product/getItemGroups";
  return await getWithResultAndBlockHandling(url);
};

export const getItemCategories = async () => {
  const url = "product/getItemCategories";
  return await getWithResultAndBlockHandling(url);
};

export const getProductGroups = async () => {
  const url = "product/getProductGroups";
  return await getWithResultAndBlockHandling(url);
};

export const getCollections = async () => {
  const url = "product/getCollections";
  return await getWithResultAndBlockHandling(url);
};

export const getBaseColors = async () => {
  const url = "product/getBaseColors";
  return await getWithResultHandling(url);
};

export const getBaseSizes = async () => {
  const url = "product/getBaseSizes";
  return await GET(url);
};

export const getGenders = async () => {
  const url = "product/getGenders";
  return await getWithBlockHandling(url);
};

export const getTags = async () => {
  const url = "product/getProductTagsBySourceType";
  const params = new URLSearchParams([["sourceType", 0]]);
  return (await getWithResultHandling(url, params))?.filter((f) => f.blocked === false);
};

export const getStartpageProducts = async () => {
  const url = "product/getStartpageProducts";
  return await GET(url);
};

export const getLatestProducts = async () => {
  const url = "product/getLatestProducts";
  return await GET(url);
};

export const getHotProducts = async () => {
  const url = "product/getHotProducts";
  return await GET(url);
};

export const getStock = async (productIdentifier) => {
  const url = "cart/getStock";
  const params = new URLSearchParams([["productIdentifier", productIdentifier]]);
  return await GET(url, params);
};

//$"InternalProduct/GetProduct/?locationCode={location}&currencyCode={currencyCode}&itemNo={itemNo}&customerNo={customerNo}";
export const getProduct = async (itemNumber) => {
  const url = "product/getProduct";
  const params = new URLSearchParams([["itemNo", itemNumber]]);
  return await GET(url, params);
};

export const getCampaigns = async () => {
  const url = "product/getCampaigns";
  return await GET(url);
};

export const filterProductsByCampaign = async (campaignId) => {
  const url = "product/filterProductsByCampaign";
  const params = new URLSearchParams([["campaignId", campaignId]]);
  return await GET(url, params);
};

export const countProducts = async (filters) => {
  const {
    searchTerm,
    programs,
    itemGroups,
    itemCategories,
    productGroups,
    collections,
    colors,
    sizes,
    genders,
    tags,
    campaignId,
  } = filters;
  const url = "product/filterCountProducts";
  const params = new URLSearchParams([]);
  if (campaignId > 0) params.append("campaignId", campaignId);
  if (searchTerm?.length > 0) params.append("searchTerm", searchTerm);
  if (programs?.length > 0) programs.map((p) => params.append("programs", p));
  if (itemGroups?.length > 0) itemGroups.map((p) => params.append("itemGroups", p));
  if (itemCategories?.length > 0) itemCategories.map((p) => params.append("itemCategories", p));
  if (productGroups?.length > 0) productGroups.map((p) => params.append("productGroups", p));
  if (collections?.length > 0) collections.map((p) => params.append("collections", p));
  if (colors?.length > 0) colors.map((p) => params.append("colors", p));
  if (sizes?.length > 0) sizes.map((p) => params.append("sizes", p));
  if (genders?.length > 0) genders.map((p) => params.append("genders", p));
  if (tags?.length > 0) tags.map((p) => params.append("tags", p));
  return await GET(url, params);
};

export const filterProducts = async (filters) => {
  const {
    campaignId,
    searchTerm,
    programs,
    itemGroups,
    itemCategories,
    productGroups,
    collections,
    colors,
    sizes,
    genders,
    tags,
    orderBy,
    orderDesc,
    pageSize,
    page,
  } = filters;
  const url = "product/filterProducts";
  const params = new URLSearchParams([
    ["pageSize", pageSize],
    ["currentPage", page],
  ]);
  if (orderBy?.length > 0) params.append("orderBy", orderBy);
  if (orderDesc?.length > 0) params.append("orderDesc", orderDesc);
  if (campaignId > 0) params.append("campaignId", campaignId);
  if (searchTerm?.length > 0) params.append("searchTerm", searchTerm);
  if (programs?.length > 0) programs.map((p) => params.append("programs", p));
  if (itemGroups?.length > 0) itemGroups.map((p) => params.append("itemGroups", p));
  if (itemCategories?.length > 0) itemCategories.map((p) => params.append("itemCategories", p));
  if (productGroups?.length > 0) productGroups.map((p) => params.append("productGroups", p));
  if (collections?.length > 0) collections.map((p) => params.append("collections", p));
  if (colors?.length > 0) colors.map((p) => params.append("colors", p));
  if (sizes?.length > 0) sizes.map((p) => params.append("sizes", p));
  if (genders?.length > 0) genders.map((p) => params.append("genders", p));
  if (tags?.length > 0) tags.map((p) => params.append("tags", p));
  return await GET(url, params);
};
