import { GET, POST } from "./http";

export const initReturn = async (orderNo, email) => {
  const url = "/return/initReturn";
  const params = new URLSearchParams([
    ["orderNo", orderNo],
    ["email", email],
  ]);
  return await GET(url, params);
};

export const getPreliminaryReturnState = async (returnKey) => {
  const url = "/return/getPreliminaryReturnState/";
  const params = new URLSearchParams([["key", returnKey]]);
  return await GET(url, params);
};

export const setPreliminaryReturnState = async (model) => {
  const url = "/return/setPreliminaryReturnState/";
  return await POST(url, model);
};

export const validateReturnRequest = async (cartNumber) => {
  const url = "/return/validateReturn/";
  const params = new URLSearchParams([["cartNumber", cartNumber]]);
  return await POST(url, {}, params);
};

export const createReturnRequest = async (customerReturnSpecification) => {
  const url = "/return/createReturn/";
  return await POST(url, JSON.stringify(customerReturnSpecification));
};

export const getReturnReasons = async () => {
  const url = "/return/returnReasons";
  return await GET(url);
};

export const getOriginalInvoice = async (orderNo, email) => {
  const url = "/return/getOriginalInvoice";
  return await POST(url, { orderNo: orderNo, email: email });
};

export const uploadProductImage = async (lineGuid, selectedFile) => {
  const url = "/return/uploadImage";
  if (selectedFile === null) {
    this.setState({ error: true });
    this.props.errorHandling("No image", true);
    return;
  }
  const formData = new FormData();
  formData.append("file", selectedFile);
  formData.append("lineGuid", lineGuid);
  return await POST(
    url,
    formData,
    {},
    {
      accept: "application/json",
      "Content-Type": `multipart/form-data; boundary=${formData._boundary}`,
    }
  );
};

export const getWelcomeText = async (orderNo) => {
  const url = "/home/welcomeText/";
  const params = new URLSearchParams([
    ["orderNo", orderNo],
    ["lang", navigator.language.split("-")[0]],
  ]);
  return await GET(url, params);
};

export const getHelpText = async (orderNo) => {
  const url = "/home/helpText/";
  const params = new URLSearchParams([["orderNo", orderNo]]);
  return await GET(url, params);
};

export const getReturnItemText = async (orderNo) => {
  const url = "/home/returnItemText/";
  const params = new URLSearchParams([["orderNo", orderNo]]);
  return await GET(url, params);
};

export const getTermsText = async (orderNo) => {
  const url = "/home/termsAndConditions/";
  const params = new URLSearchParams([["orderNo", orderNo]]);
  return await GET(url, params);
};

export const getReplacementStoreText = async (orderNo) => {
  const url = "/home/replacementStoreText/";
  const params = new URLSearchParams([["orderNo", orderNo]]);
  return await GET(url, params);
};

export const getCheckoutText = async (orderNo) => {
  const url = "/home/checkoutText/";
  const params = new URLSearchParams([["orderNo", orderNo]]);
  return await GET(url, params);
};

export const getThankYouReturnText = async (orderNo) => {
  const url = "/home/thankYouReturnText/";
  const params = new URLSearchParams([["orderNo", orderNo]]);
  return await GET(url, params);
};

export const getThankYouClaimText = async (orderNo) => {
  const url = "/home/thankYouClaimText/";
  const params = new URLSearchParams([["orderNo", orderNo]]);
  return await GET(url, params);
};

export const getLabelErrorText = async (orderNo) => {
  const url = "/home/labelErrorText/";
  const params = new URLSearchParams([["orderNo", orderNo]]);
  return await GET(url, params);
};

export const getLogo = async (shopNo) => {
  const url = "/home/logo/";
  const params = new URLSearchParams([["shopNo", shopNo]]);
  return await GET(url, params);
};

export const getConfig = async (orderNo) => {
  const url = "/home/config/";
  const params = new URLSearchParams([["orderNo", orderNo]]);
  return await GET(url, params);
};

export const getCheckoutKey = async (orderNo) => {
  const url = "/home/checkoutConfig/";
  const params = new URLSearchParams([["orderNo", orderNo]]);
  return await GET(url, params);
};

export const getReturnLabel = async (cartNumber, returnMode) => {
  const url = "/return/getReturnLabel/";
  return await POST(url, { cartNumber: cartNumber, returnMode: returnMode });
};
