import React from "react";
import { ThemeProvider } from "@emotion/react";
import { createTheme } from "@mui/material/styles";
import { Container, CssBaseline } from "@mui/material";
import StartPage from "./pages/StartPage";
import ReturnContextProvider from "./contexts/ReturnContext";

export default function App(props) {
  const theme = createTheme({
    palette: {
      primary: {
        main: "#212121",
        light: "#484848",
        dark: "#000000",
        contrastText: "#fff",
      },
      secondary: {
        main: "#212121",
        light: "#484848",
        dark: "#000000",
        contrastText: "#fff",
      },
    },
    typography: {
      fontFamily: ["Lato", "sans-serif"].join(","),
      h1: {
        fontFamily: ["Bebas Neue", "cursive"].join(","),
      },
      h2: {
        fontFamily: ["Bebas Neue", "cursive"].join(","),
      },
      h3: {
        fontFamily: ["Bebas Neue", "cursive"].join(","),
      },
      h4: {
        fontFamily: ["Bebas Neue", "cursive"].join(","),
      },
      h5: {
        fontFamily: ["Bebas Neue", "cursive"].join(","),
      },
      h6: {
        fontFamily: ["Bebas Neue", "cursive"].join(","),
      },
      overline: { fontFamily: ["Lato", "sans-serif"].join(","), fontSize: "1rem", lineHeight: "1rem" },
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Container component="main" disableGutters maxWidth={false}>
        <ReturnContextProvider>
          <StartPage />
        </ReturnContextProvider>
      </Container>
    </ThemeProvider>
  );
}
