import { useQuery } from "@tanstack/react-query";
import React, { useContext } from "react";
import { clearSession, getSessionObject, storeSessionObject } from "../services/storageService";
import { Button, Card, CardContent, CircularProgress, Typography } from "@mui/material";
import strings from "../localization/localizedStrings";
import { getLabelErrorText, getReturnLabel, getThankYouClaimText, getThankYouReturnText } from "../services/returnService";
import { ReturnContext } from "../contexts/ReturnContext";
import { Buffer } from "buffer";

export default function Thanks(props) {
  const [checkoutParams, setCheckoutParams] = React.useState({
    orderNo: "",
    email: "",
    cartNo: "",
    returnMode: "",
    ready: false,
  });
  const [text, setText] = React.useState("");
  const [pdfUrl, setPdfUrl] = React.useState(null);
  const { config } = useContext(ReturnContext);

  const thanksReturnTextQuery = useQuery({
    queryHash: `thanksReturnTextQuery${checkoutParams.orderNo}`,
    queryKey: ["thanksReturnTextQuery"],
    queryFn: async () => getThankYouReturnText(checkoutParams.orderNo),
    refetchOnWindowFocus: false,
    staleTime: Infinity,
    enabled: checkoutParams.ready,
  });

  const thanksClaimsTextQuery = useQuery({
    queryHash: `thanksClaimsTextQuery${checkoutParams.orderNo}`,
    queryKey: ["thanksClaimsTextQuery"],
    queryFn: async () => getThankYouClaimText(checkoutParams.orderNo),
    refetchOnWindowFocus: false,
    staleTime: Infinity,
    enabled: checkoutParams.ready,
  });

  const labelErrorTextQuery = useQuery({
    queryHash: `labelErrorTextQuery${checkoutParams.orderNo}`,
    queryKey: ["labelErrorTextQuery"],
    queryFn: async () => getLabelErrorText(checkoutParams.orderNo),
    refetchOnWindowFocus: false,
    staleTime: Infinity,
    enabled: checkoutParams.ready,
  });

  const returnLabelQuery = useQuery({
    queryHash: `returnLabelQuery${checkoutParams.cartNo}`,
    queryKey: ["returnLabelQuery"],
    queryFn: async () => getReturnLabel(checkoutParams.cartNo, checkoutParams.returnMode),
    refetchOnWindowFocus: false,
    staleTime: Infinity,
    enabled: checkoutParams.ready && config.createReturnLabel && checkoutParams.returnMode === "return",
    retry: false,
  });

  React.useEffect(() => {
    const checkoutOrderNo = getSessionObject("checkoutOrderNo");
    const checkoutEmail = getSessionObject("checkoutEmail");
    const checkoutReturnMode = getSessionObject("checkoutReturnMode");
    const checkoutCartNo = getSessionObject("checkoutCartNo");
    setCheckoutParams({
      orderNo: checkoutOrderNo,
      email: checkoutEmail,
      cartNo: checkoutCartNo,
      returnMode: checkoutReturnMode,
      ready: true,
    });
  }, []);

  React.useEffect(() => {
    if (!checkoutParams.ready) return;
    const useText = checkoutParams.returnMode === "return" ? thanksReturnTextQuery.data : thanksClaimsTextQuery.data;
    setText(useText);
  }, [checkoutParams.ready, checkoutParams.returnMode, thanksReturnTextQuery.data, thanksClaimsTextQuery.data]);

  React.useEffect(() => {
    if (!checkoutParams.ready) return;
  }, [returnLabelQuery.isFetching]);

  React.useEffect(() => {
    if (returnLabelQuery.data) {
      const byteCharacters = Buffer.from(returnLabelQuery.data.labels[0].base64pdf, "base64");
      const blob = new Blob([byteCharacters], { type: "application/pdf" });
      const url = URL.createObjectURL(blob);
      setPdfUrl(url);
    }
  }, [returnLabelQuery.data]);

  return (
    <div style={{ width: "40vh", marginTop: "5vh", marginLeft: "auto", marginRight: "auto" }}>
      <Card sx={{ width: "100%" }}>
        <CardContent>
          <Typography variant="h4">{strings.returnStep3.thankeeSai1}</Typography>
          <Typography variant="h6">
            <span dangerouslySetInnerHTML={{ __html: text }} />
          </Typography>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              const tokenParams = {
                orderNumber: checkoutParams.orderNo,
                email: checkoutParams.email,
              };
              const dummySearch = JSON.stringify(tokenParams);
              const dummyToken = Buffer.from(dummySearch).toString("base64");
              clearSession();
              window.location.href = `/?oi=${dummyToken}`;
            }}
          >
            {strings.lastStep.restart}
          </Button>
        </CardContent>
      </Card>
      {config.createReturnLabel && checkoutParams.returnMode === "return" && (
        <>
          <br />
          <Card sx={{ width: "100%" }}>
            <CardContent>
              <Typography variant="h4">{strings.returnStep3.returnLabel}</Typography>
              {returnLabelQuery.isFetching && <CircularProgress />}
              {returnLabelQuery.isSuccess && (
                <div>
                  <a href={pdfUrl} target="_blank" rel="noreferrer">
                    {strings.lastStep.labelLink}
                  </a>
                </div>
              )}
              {returnLabelQuery.isError && (
                <div>
                  <Typography variant="h6">
                    <span dangerouslySetInnerHTML={{ __html: labelErrorTextQuery.data }} />
                  </Typography>
                </div>
              )}
            </CardContent>
          </Card>
        </>
      )}
    </div>
  );
}
