import React from "react";
import { FilterContext } from "../../contexts/FilterContext";
import { getCampaigns } from "../../services/productService";
import { useQuery } from "@tanstack/react-query";

const ImageCampaigns = (props) => {
  const { filters, setFilters } = React.useContext(FilterContext);
  const campaigns = useQuery({
    queryKey: ["campaignQuery"],
    queryFn: async () => getCampaigns(),
    refetchOnWindowFocus: false,
    staleTime: Infinity,
  });
  if (campaigns.isLoading) {
    return <p>Loading...</p>;
  }

  if (campaigns.isError) {
    return <p>Error: {campaigns.error.message}</p>;
  }

  if (campaigns.data?.length === 0) return null;

  return (
    <div style={{ marginTop: "50px" }}>
      {campaigns.data
        ?.filter((f) => f.imageUrl !== null)
        .map((campaign, index) => (
          <div
            key={index}
            style={{
              position: "relative",
              width: "100%",
              height: "100px",
              paddingBottom: "33%",
              backgroundImage: `url(${campaign.imageUrl})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
              marginBottom: "20px",
              cursor: "pointer",
            }}
            onClick={() => setFilters({ ...filters, campaignId: campaign.id })}
          >
            <div
              style={{
                position: "absolute",
                // top: 0,
                // left: 0,
                // right: 0,
                // bottom: 0,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                padding: "16px",
              }}
            >
              <h1
                style={{
                  fontFamily: "Bebas Neue, cursive",
                  fontSize: "2rem",
                  color: "white",
                  marginBottom: "8px",
                  textShadow: "1px 1px 3px rgba(0, 0, 0, 0.6)",
                }}
              >
                {campaign.description}
              </h1>
              <p
                class="description"
                style={{
                  fontFamily: "Bebas Neue, cursive",
                  fontSize: "1.25rem",
                  color: "white",
                  textShadow: "1px 1px 3px rgba(0, 0, 0, 0.6)",
                }}
              >
                {campaign.text}
              </p>
            </div>
          </div>
        ))}
    </div>
  );
};

export default ImageCampaigns;
