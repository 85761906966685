import React from "react";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import strings from "../localization/localizedStrings";
import ReturnLines from "../components/ReturnLines";
import ReturnLinesMobile from "../components/ReturnLinesMobile";
import { useHistory } from "react-router-dom";
import { ReturnContext } from "../contexts/ReturnContext";
import PageBar from "../components/PageBar";
import { Card, CardContent, CircularProgress, Grid, Typography } from "@mui/material";
import BottomBar from "../components/BottomBar";
import { getReturnItemText } from "../services/returnService";
import useWindowDimensions from "../hooks/useWindowDimensions";

export default function ReturnPage(props) {
  const { initReturn, setActiveStep, returnConf, setErrorState, disableUI, config, returnMode, invoice } = React.useContext(ReturnContext);
  const queryClient = useQueryClient();
  const { width } = useWindowDimensions();
  const history = useHistory();

  React.useEffect(() => {
    setActiveStep(1);
  }, [setActiveStep]);

  const back = () => {
    //queryClient.invalidateQueries("invoiceQuery");
    //window.location.href = "/";
    history.push("/returnMode");
  };

  const returnItemTextQuery = useQuery({
    queryKey: ["returnItemTextQuery"],
    queryFn: async () => getReturnItemText(invoice.header?.orderNo),
    queryHash: `returnItemTextQuery`,
    enabled: true,
    refetchOnWindowFocus: false,
    staleTime: Infinity,
    retry: 0,
  });

  const persistStep = async () => {
    if (returnConf.returnLines.length === 0) {
      setErrorState({ hasError: true, errorMsg: strings.returnStep1.errorNoItems });
      return;
    }
    if (returnConf.returnLines.filter((f) => !f.returnReason || f.returnReason === "").length > 0) {
      setErrorState({ hasError: true, errorMsg: strings.returnStep1.errorNoReason });
      return;
    }
    if (returnConf.returnLines.filter((f) => f.photoRequired && !f.uploadedImage).length > 0) {
      setErrorState({ hasError: true, errorMsg: strings.returnStep1.errorNoImage });
      return;
    }
    if (returnConf.returnLines.filter((f) => f.commentRequired && f.returnComment === "").length > 0) {
      setErrorState({ hasError: true, errorMsg: strings.returnStep1.errorNoComment });
      return;
    }

    await initReturn();
    if (config.enableReplacementStore && returnMode === "return") history.push("/replace");
    else history.push("/confirm");
  };

  if (disableUI) {
    return (
      <div style={{ display: "flex", justifyContent: "center", marginTop: "6%" }}>
        <Grid container direction="column" justifyContent="center" alignItems="stretch" spacing={2} xs={12} md={5} xl={4}>
          <Grid item xs style={{ textAlign: "center" }}>
            <CircularProgress color="primary" />
          </Grid>
          <Grid item xs style={{ textAlign: "center" }}>
            <Typography>{strings.returnStep1.loadingReturn}</Typography>
          </Grid>
        </Grid>
      </div>
    );
  }
  return (
    <>
      <div style={{ zIndex: 1, marginTop: "3vh" }}>
        <PageBar previousStep={() => back()} nextStep={() => persistStep()} />
        <div style={{ marginTop: 10, width: "95vw", maxWidth: "1200px", marginLeft: "auto", marginRight: "auto" }}>
          <Grid container direction="row" justifyContent="center" alignItems="center" style={{ marginTop: 15, marginBottom: 15, width: "100%" }}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Card>
                <CardContent>
                  <Typography variant="body2">
                    <span dangerouslySetInnerHTML={{ __html: returnItemTextQuery?.data ?? "" }} />
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
          {width > 900 && <ReturnLines />}
          {width <= 900 && <ReturnLinesMobile />}
        </div>
      </div>
      <BottomBar previousStep={() => back()} nextStep={() => persistStep()} />
    </>
  );
}
