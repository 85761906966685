import LocalizedStrings from "react-localization";

const nb = {
  pageLanding: {
    loadingReturn: "Vennligst vent, finner ordren din...",
    welcomeText: "welcomeText",
    buttonText: "Opprett retur / refusjon",
    modeChoice: "Velg retur eller reklamasjon",
    modeChoice1: "Retur",
    modeChoice1Text: "Jeg ønsker å returnere en eller flere gjenstander",
    modeChoice1BlockedText: "Denne bestillingen er forbi den tillatte returperioden",
    modeChoice2: "Reklamasjon",
    modeChoice2Text: "Jeg ønsker å åpne et krav på en eller flere gjenstander",
  },
  returnSteps: {
    zero: "Velg ordre",
    one: "Velg varer",
    two: "Velg erstatningsvarer",
    three: "Bekreft forespørsel",
  },
  claimSteps: {
    zero: "Velg ordre",
    one: "Velg varer",
    two: "Bekreft forespørsel",
  },
  orderHeader: {
    header: "Min opprinnelige ordre",
    customer: "Kunde",
    returnReplacement: "Retur / erstatning for ordre ",
    returnValue: "Returverdi",
    headerStep1: "Retur / erstatning for ordre ",
    headerStep2: "Mine erstatningsvarer for ordre ",
  },
  cart: {
    header: "Mine erstatningsvarer",
  },
  returnStep1: {
    header: "Velg hvilke varer du vil inkludere",
    buttonOk: "Fortsett til erstatning",
    buttonCancel: "Avbryt returforespørsel",
    errorNoItems: "Ingen varer valgt",
    errorNoReason: "En eller flere varer mangler returbegrunnelse",
    errorNoImage: "En eller flere varer mangler bilde",
    errorNoComment: "En eller flere varer mangler kommentar",
    pagebarText: "Velg hvilke varer du vil inkludere",
    optKeep: "Behold",
    optReturn: "Retur",
    optClaim: "Klage",
    optChangeVariant: "Endre farge/størrelse",
    optChangeOther: "Send ny vare",
    photoWarning: "Vennligst last opp et bilde av varen",
    commentWarning: "Vennligst skriv en kommentar",
    photoOk: "Bilde lastet opp",
    comment: "Skriv en kommentar",
    pickReplacement: "Velg en annen variant",
    loadingReturn: "Vennligst vent, lagrer valgene dine...",
    viewImage: "Vis bilde",
    optNoChangeNoStock: "Varen er utsolgt, ingen erstatning mulig",
    changeProductWithReplacementStore: "Refusjon / endre vare",
    changeProductNoReplacementStore: "Refusjon",
    buttonChangeToColorSize: "Bytt til denne fargen / størrelsen",
    changeToColor: "Bytt til farge",
    changeToSize: "Bytt til størrelse",
    cchangeToLength: "Bytt til lengde",
  },
  returnStep2: {
    buttonOk: "Fortsett",
    buttonCancel: "Tilbake",
    addItems: "Legg til erstatningsprodukter",
    emptyReplacementList: "Ingen erstatningsprodukter valgt",
    pagebarText: "Finn nye erstatningsprodukter",
  },
  returnStep3: {
    errorAgreement: "Du må godta vilkårene og betingelsene",
    errorRefundOption: "Du må velge en refusjonsopsjon først",
    errorValidation: "Noe gikk galt under valideringen av returanmodningen din, vennligst kontakt vår kundeservice for å løse dette problemet",
    termsAndConditions: "Jeg godtar vilkårene og betingelsene",
    headerReplacementItems: "Erstatningsvarer",
    headerReturnItems: "Varer å returnere",
    headeClaimItems: "Varer å klage på",
    headerRefunds: "Refusjonsalternativer",
    agreeButtonReturn: "Opprett retur",
    agreeButtonClaim: "Opprett refusjon",
    paymentButton: "Fortsett til betaling",
    thankeeSai1: "Takk!",
    thankeeSai2: "Vi har mottatt returanmodningen din og vil komme tilbake til deg.",
    stepperText: "Alt gjort her!",
    pagebarText: "Dine nåværende returvalg",
    refundOptionNotSet: "Ingen refusjonsopsjon valgt ennå",
    headerToPay: "Å betale",
    headerToRefund: "Å refundere",
    refundOfferHeader: "Refusjonstilbud!",
    refundOfferText: "Velg {0} refundert til din opprinnelige betalingsmetode eller en {1} kupong!",
    refundOfferNoThanks: "Nei takk",
    refundOfferYesPlease: "Jeg tar kupongen!",
    refundOfferMini: "Se vårt refusjonstilbud",
    refundVoucer: "Kupong",
    readTerms: "Vis vilkår",
    returnLabel: "Returetikett",
  },
  lastStep: {
    labelError: "Beklager, vi kunne ikke generere en returforsendelsesetikett for øyeblikket. Ta kontakt med kundesupport for hjelp.",
    labelLink: "Åpne returetikett",
    restart: "Opprett ny retur",
  },
  productBrowser: {
    loadingStore: "Vennligst vent mens butikken lastes inn...",
    title: "Legg til erstatningsvarer",
    itemgroup_program: "Merke",
    itemgroup_itemgroup: "Varegruppe",
    itemgroup_itemcat: "Varekategori",
    itemgroup_prodgroup: "Produktgruppe",
    itemgroup_collection: "Samling",
    itemgroup_color: "Farge",
    itemgroup_size: "Størrelse",
    itemgroup_length: "Lengde",
    itemgroup_tag: "Tags",
    itemgroup_gender: "Kjønn",
    pickVariant: "Velg variant",
    filtered: "Filtrert",
    search: "Søk",
    showMoreItems: "Vis flere..",
    buttonAddCart: "Legg i handlekurv",
    prodInfoHeader: "Produktinformasjon",
    prodInfoItemNo: "Varenummer",
    inStock: "Varen er på lager",
    outOfStock: "Varen er ikke på lager",
    loadMore: "Last inn mer",
    activeFilters: "Aktive filtre",
    productsHotTitle: "Populære produkter",
    productsNewTitle: "Nye produkter",
    productsFound: "#count# produkter funnet",
    addCartMessage: "Varen er lagt i handlekurven",
    buttonShowItems: "Vis #countsQuery# artikler",
  },
  bottomBar: {
    prevStep: "Tilbake",
    nextStep: "Neste",
  },
  OK: "OK",
  yes: "Ja",
  no: "Nei",
  color: "Farge",
  size: "Størrelse",
  length: "Lengde",
  price: "Pris",
  orderNumber: "Ordrenummer",
  email: "E-post",
  errorMessage: "Oops!",
  returnFreight: "Returfrakt",
  loadingPortal: "Vennligst vent mens returappen lastes inn...",
};

let strings = new LocalizedStrings({
  en: {
    pageLanding: {
      loadingReturn: "One moment please, finding your order...",
      welcomeText: "welcomeText",
      buttonText: "Create return / claim",
      modeChoice: "Choose return or claim",
      modeChoice1: "Return",
      modeChoice1Text: "I want to return one or more items",
      modeChoice1BlockedText: "This order is passed the allowed return period",
      modeChoice2: "Claim",
      modeChoice2Text: "I want to open a claim on one or more items",
    },
    returnSteps: {
      zero: "Choose order",
      one: "Choose items",
      two: "Choose replacement items",
      three: "Confirm request",
    },
    claimSteps: {
      zero: "Choose order",
      one: "Choose items",
      two: "Confirm request",
    },
    orderHeader: {
      header: "My original order",
      customer: "Customer",
      returnReplacement: "Return / replacement for order ",
      returnValue: "Return value",
      headerStep1: "Return / replacement for order ",
      headerStep2: "My replacement items for order ",
    },
    cart: {
      header: "My replacement items",
    },
    returnStep1: {
      header: "Choose which items to include",
      buttonOk: "Proceed to replacement",
      buttonCancel: "Cancel return request",
      errorNoItems: "No items chosen",
      errorNoReason: "One ore more items missing return reason specification",
      errorNoImage: "One ore more items missing photo",
      errorNoComment: "One ore more items missing comment",
      pagebarText: "Choose which items to include",
      optKeep: "Keep",
      optReturn: "Return",
      optClaim: "Complaint",
      optChangeVariant: "Change color/size",
      optChangeOther: "Send new item",
      photoWarning: "Please upload an image of the item",
      commentWarning: "Please write a comment",
      photoOk: "Image uploaded",
      comment: "Write a comment",
      pickReplacement: "Pick another variant",
      loadingReturn: "One moment, saving your options...",
      viewImage: "View image",
      optNoChangeNoStock: "Item is out of stock, no replacement possible",
      changeProductWithReplacementStore: "Refund or replace item",
      changeProductNoReplacementStore: "Refund",
      buttonChangeToColorSize: "Change to this color/size",
      changeToColor: "Change to color",
      changeToSize: "Change to size",
      changeToLength: "Change to length",
    },
    returnStep2: {
      buttonOk: "Continue",
      buttonCancel: "Back",
      addItems: "Add replacement products",
      emptyReplacementList: "No replacement products choosen",
      pagebarText: "Find new replacement items",
    },
    returnStep3: {
      errorAgreement: "You need to agree to the terms and conditions",
      errorRefundOption: "You need to pick a redunf option first",
      errorValidation: "Something went wrong during the validation of your return request, please contact our customer support to resolve this issue",
      termsAndConditions: "I agree to the terms and conditions",
      headerReplacementItems: "Replacement items",
      headerReturnItems: "Items to return",
      headeClaimItems: "Items to complain about",
      headerRefunds: "Refund options",
      agreeButtonReturn: "Create return",
      agreeButtonClaim: "Create claim",
      paymentButton: "Proceed to payment",
      thankeeSai1: "Thank you!",
      thankeeSai2: "We have received your return request and will get back to you.",
      stepperText: "All done here!",
      pagebarText: "Your current return choices",
      refundOptionNotSet: "No refund option choosen yet",
      headerToPay: "To pay",
      headerToRefund: "To refund",
      refundOfferHeader: "Refund offer!",
      refundOfferText: "Choose {0} refunded to your original payment method or a {1} voucher!",
      refundOfferNoThanks: "No thanks",
      refundOfferYesPlease: "I'll take the voucher!",
      refundOfferMini: "See our refund offer",
      refundVoucer: "Voucher",
      readTerms: "View terms",
      returnLabel: "Return label",
    },
    lastStep: {
      labelError: "Sorry, we were unable to generate a return shipping label at this time. Please contact customer support for assistance.",
      labelLink: "Open return label",
      restart: "Create new return",
    },
    productBrowser: {
      loadingStore: "One moment please while the store is loading...",
      title: "Add replacement items",
      itemgroup_program: "Brand",
      itemgroup_itemgroup: "Item Group",
      itemgroup_itemcat: "Item Category",
      itemgroup_prodgroup: "Product Group",
      itemgroup_collection: "Collection",
      itemgroup_color: "Color",
      itemgroup_size: "Size",
      itemgroup_length: "Length",
      itemgroup_tag: "Tags",
      itemgroup_gender: "Gender",
      pickVariant: "Pick variant",
      filtered: "Filtered",
      search: "Search",
      showMoreItems: "Show more..",
      buttonAddCart: "Add to cart",
      prodInfoHeader: "Product information",
      prodInfoItemNo: "Item no.",
      inStock: "Item is on stock",
      outOfStock: "Item is not on stock",
      loadMore: "Load more",
      activeFilters: "Active filters",
      productsHotTitle: "Popular products",
      productsNewTitle: "New products",
      productsFound: "Showing #count# products",
      addCartMessage: "Item added to cart",
      buttonShowItems: "Show #countsQuery# products",
    },
    bottomBar: {
      prevStep: "Back",
      nextStep: "Next",
    },
    OK: "OK",
    yes: "Yes",
    no: "No",
    color: "Color",
    size: "Size",
    length: "Length",
    price: "Price",
    orderNumber: "Order No.",
    email: "Email",
    errorMessage: "Ooops!",
    returnFreight: "Return freight",
    loadingPortal: "One moment please, loading return app...",
  },
  sv: {
    pageLanding: {
      loadingReturn: "Ett ögonblick, letar upp din order...",
      welcomeText: "welcomeText",
      buttonText: "Skapa retur / reklamation",
      modeChoice: "Välj retur eller reklamation",
      modeChoice1: "Retur",
      modeChoice1Text: "Jag vill returnera en eller flera varor",
      modeChoice1BlockedText: "Denna order är utanför den tillåtna returperioden",
      modeChoice2: "Reklamation",
      modeChoice2Text: "Jag vill reklamera en eller flera varor",
    },
    returnSteps: {
      zero: "Välj retur/reklamation",
      one: "Välj artiklar",
      two: "Välj ersättningsartiklar",
      three: "Bekräfta return",
    },
    claimSteps: {
      zero: "Välj retur/reklamation",
      one: "Välj artiklar",
      two: "Bekräfta reklamation",
    },
    orderHeader: {
      header: "Min ursprungsorder",
      customer: "Kunduppgifter",
      returnReplacement: "Retur / byte av order ",
      returnValue: "Returvärde",
      headerStep1: "Retur / byte av order ",
      headerStep2: "Mina ersättningsartiklar för order ",
    },
    cart: {
      header: "Mina ersättningsartiklar",
    },
    returnStep1: {
      header: "Välj vilka artiklar ditt ärende gäller",
      buttonOk: "Gå vidare",
      buttonCancel: "Avbryt retur",
      errorNoItems: "Inga artiklar valda",
      errorNoReason: "En eller flera artiklar saknar returorsak",
      errorNoImage: "En eller flera artiklar saknar bild",
      errorNoComment: "En eller flera artiklar saknar kommentar",
      pagebarText: "Välj artiklar",
      optKeep: "Behåll",
      optReturn: "Returnera",
      optClaim: "Reklamera",
      optChangeVariant: "Byt färg / storlek",
      optChangeOther: "Byt till ny vara",
      photoWarning: "Vänligen bifoga en bild på varans skick",
      commentWarning: "Vänligen skriv en kommentar om varans skick",
      photoOk: "Bild bifogad",
      comment: "Skriv en kommentar",
      pickReplacement: "Välj ny variant",
      loadingReturn: "Ett ögonblick, sparar dina val...",
      viewImage: "Visa bild",
      optNoChangeNoStock: "Varan är slut i lager, byte ej möjligt",
      changeProductWithReplacementStore: "Byt till annan vara eller få ersättning",
      changeProductNoReplacementStore: "Få ersättning",
      buttonChangeToColorSize: "Byt till denna färg / storlek",
      changeToColor: "Byt till färg",
      changeToSize: "Byt till storlek",
      changeToLength: "Byt till längd",
    },
    returnStep2: {
      buttonOk: "Gå vidare",
      buttonCancel: "Tillbaka",
      addItems: "Lägg till ersättningsartiklar",
      emptyReplacementList: "Inga ersättningsartiklar valda",
      pagebarText: "Hitta nya ersättningsartiklar",
    },
    returnStep3: {
      errorAgreement: "Vänligen godkänn returvillkoren",
      errorRefundOption: "Välj ersättningstyp innan du skapar din retur",
      errorValidation: "Något gick fel när din returbegäran skulle godkännas, vänligen kontakta vår kundtjänst",
      termsAndConditions: "Jag godkänner returvillkoren",
      headerReplacementItems: "Ersättningsartiklar",
      headerReturnItems: "Artiklar att returnera",
      headeClaimItems: "Artiklar att reklamera",
      headerRefunds: "Ersättningsval",
      agreeButtonReturn: "Skapa returärende",
      agreeButtonClaim: "Skapa reklamationsärende",
      paymentButton: "Gå till betalning",
      thankeeSai1: "Tack!",
      thankeeSai2: "Vi har mottagit din returbegäran och återkommer med besked.",
      stepperText: "Din retur är klar!",
      pagebarText: "Sammanställning av dina val",
      refundOptionNotSet: "Ingen ersättningstyp vald ännu",
      headerToPay: "Att betala",
      headerToRefund: "Att återbetala",
      refundOfferHeader: "Erbjudande!",
      refundOfferText: "Välj mellan {0} tillbaka på ditt ursprungliga betalsätt eller {1} som presentkort i vår butik!",
      refundOfferNoThanks: "Nej tack",
      refundOfferYesPlease: "Välj presentkort",
      refundOfferMini: "Se vårt erbjudande",
      refundVoucer: "Presentkort",
      readTerms: "Läs villkoren",
      returnLabel: "Returetikett",
    },
    lastStep: {
      labelError: "Tyvärr kunde inte en returfraktsedel genereras just nu. Vänligen kontakta vår kundtjänst för hjälp.",
      labelLink: "Öppna retursedel",
      restart: "Skapa ny retur",
    },
    productBrowser: {
      loadingStore: "Ett ögonblick, butik laddar...",
      title: "Välj ersättningsartikel",
      itemgroup_program: "Varumärke",
      itemgroup_itemgroup: "Varugrupp",
      itemgroup_itemcat: "Artikelkategori",
      itemgroup_prodgroup: "Produktgrupp",
      itemgroup_collection: "Kollektion",
      itemgroup_color: "Färg",
      itemgroup_size: "Storlek",
      itemgroup_length: "Längd",
      itemgroup_tag: "Taggar",
      itemgroup_gender: "Kön",
      pickVariant: "Välj variant",
      filtered: "Filter",
      search: "Sök",
      showMoreItems: "Visa fler..",
      buttonAddCart: "Lägg i varukorgen",
      prodInfoHeader: "Produktinformation",
      prodInfoItemNo: "Artikelnummer",
      inStock: "Varan finns i lager",
      outOfStock: "Varan är tyvärr slut",
      loadMore: "visa fler",
      activeFilters: "Aktiva filter",
      productsHotTitle: "Populära artiklar",
      productsNewTitle: "Nya artiklar",
      productsFound: "Visar #count# artiklar",
      addCartMessage: "Artikeln lagd i varukorgen",
      buttonShowItems: "Visa #countsQuery# artiklar",
    },
    bottomBar: {
      prevStep: "Gå tillbaka",
      nextStep: "Gå vidare",
    },
    OK: "OK",
    yes: "Ja",
    no: "Nej",
    color: "Färg",
    size: "Storlek",
    length: "Längd",
    price: "Pris",
    orderNumber: "Ordernummer",
    email: "E-post",
    errorMessage: "Hoppsan, något gick fel!",
    returnFreight: "Returfrakt",
    loadingPortal: "Ett ögonblick, laddar appen...",
  },
  //da: {
  //  pageLanding: {
  //    loadingReturn: "Et øjeblik, finder din ordre...",
  //    welcomeText: "Velkomsttekst",
  //    buttonText: "Opret retur",
  //    modeChoice: "Vælg retur eller reklamation",
  //    modeChoice1: "Retur",
  //    modeChoice1Text: "Jeg ønsker at returnere en eller flere varer",
  //    modeChoice1BlockedText: "Denne ordre er uden for den tilladte returperiode",
  //    modeChoice2: "Reklamation",
  //    modeChoice2Text: "Jeg ønsker at åbne en reklamation på en eller flere varer",
  //  },
  //  returnSteps: {
  //    zero: "Vælg ordre",
  //    one: "Vælg varer",
  //    two: "Vælg erstatningsvarer",
  //    three: "Bekræft anmodning",
  //  },
  //  claimSteps: {
  //    zero: "Vælg ordre",
  //    one: "Vælg varer",
  //    two: "Bekræft anmodning",
  //  },
  //  orderHeader: {
  //    header: "Min oprindelige ordre",
  //    customer: "Kunde",
  //    returnReplacement: "Retur / erstatning for ordre ",
  //    returnValue: "Returværdi",
  //    headerStep1: "Retur / erstatning for ordre ",
  //    headerStep2: "Mine erstatningsvarer for ordre ",
  //  },
  //  cart: {
  //    header: "Mine erstatningsvarer",
  //  },
  //  returnStep1: {
  //    header: "Vælg hvilke varer der skal inkluderes",
  //    buttonOk: "Fortsæt til erstatning",
  //    buttonCancel: "Annuller returanmodning",
  //    errorNoItems: "Ingen varer valgt",
  //    errorNoReason: "Én eller flere varer mangler angivelse af returårsag",
  //    errorNoImage: "Én eller flere varer mangler billede",
  //    errorNoComment: "Én eller flere varer mangler kommentar",
  //    pagebarText: "Vælg hvilke varer der skal inkluderes",
  //    optKeep: "Behold",
  //    optReturn: "Returner",
  //    optClaim: "Reklamation",
  //    optChangeVariant: "Skift farve/størrelse",
  //    optChangeOther: "Send ny vare",
  //    photoWarning: "Upload venligst et billede af varen",
  //    commentWarning: "Skriv venligst en kommentar",
  //    photoOk: "Billede uploadet",
  //    comment: "Skriv en kommentar",
  //    pickReplacement: "Vælg en anden variant",
  //    loadingReturn: "Et øjeblik, gemmer dine valg...",
  //    viewImage: "Se billede",
  //    optNoChangeNoStock: "Varen er udsolgt, ingen erstatning mulig",
  //    changeProductWithReplacementStore: "Skift vare / refundering",
  //    changeProductNoReplacementStore: "Refundering",
  //  },
  //  returnStep2: {
  //    buttonOk: "Fortsæt",
  //    buttonCancel: "Tilbage",
  //    addItems: "Tilføj erstatningsvarer",
  //    emptyReplacementList: "Ingen erstatningsvarer valgt",
  //    pagebarText: "Find nye erstatningsvarer",
  //  },
  //  returnStep3: {
  //    errorAgreement: "Du skal acceptere betingelserne og vilkårene",
  //    errorRefundOption: "Du skal vælge en tilbagebetalingsmulighed først",
  //    errorValidation:
  //      "Der opstod en fejl under valideringen af din returanmodning. Kontakt venligst vores kundesupport for at løse dette problem",
  //    termsAndConditions: "Jeg accepterer betingelserne og vilkårene",
  //    headerReplacementItems: "Erstatningsvarer",
  //    headerReturnItems: "Varer til returnering",
  //    headeClaimItems: "Varer til reklamation",
  //    headerRefunds: "Tilbagebetalingsmuligheder",
  //    agreeButton: "Opret retur",
  //    paymentButton: "Fortsæt til betaling",
  //    thankeeSai1: "Tak!",
  //    thankeeSai2: "Vi har modtaget din returanmodning og vender tilbage til dig.",
  //    stepperText: "Alt færdigt her!",
  //    pagebarText: "Dine aktuelle valg vedrørende retur",
  //    refundOptionNotSet: "Ingen tilbagebetalingsmulighed valgt endnu",
  //    headerToPay: "At betale",
  //    headerToRefund: "At refundere",
  //    refundOfferHeader: "Tilbagebetalingsforslag!",
  //    refundOfferText: "Vælg at få {0} refunderet til din oprindelige betalingsmetode eller en {1} gavekort!",
  //    refundOfferNoThanks: "Nej tak",
  //    refundOfferYesPlease: "Jeg tager gerne gavekortet!",
  //    refundOfferMini: "Se vores tilbagebetalingsforslag",
  //    refundVoucer: "Gavekort",
  //    readTerms: "Vis betingelser",
  //    returnLabel: "Returlabel",
  //  },
  //  lastStep: {
  //    labelError:
  //      "Beklager, vi kunne ikke generere en returlabel på dette tidspunkt. Kontakt venligst kundesupport for hjælp.",
  //    labelLink: "Åbn returlabel",
  //    restart: "Opret ny retur",
  //  },
  //  productBrowser: {
  //    loadingStore: "Et øjeblik, indlæser butikken...",
  //    title: "Tilføj erstatningsvarer",
  //    itemgroup_program: "Mærke",
  //    itemgroup_itemgroup: "Varegruppe",
  //    itemgroup_itemcat: "Varekategori",
  //    itemgroup_prodgroup: "Produktgruppe",
  //    itemgroup_collection: "Kollektion",
  //    itemgroup_color: "Farve",
  //    itemgroup_size: "Størrelse",
  //    itemgroup_tag: "Tags",
  //    pickVariant: "Vælg variant",
  //    filtered: "Filtreret",
  //    search: "Søg",
  //    showMoreItems: "Vis flere..",
  //    buttonAddCart: "Tilføj til kurv",
  //    prodInfoHeader: "Produktinformation",
  //    prodInfoItemNo: "Varenummer",
  //    inStock: "Varen er på lager",
  //    outOfStock: "Varen er ikke på lager",
  //    loadMore: "Indlæs mere",
  //    activeFilters: "Aktive filtre",
  //    productsHotTitle: "Populære produkter",
  //    productsNewTitle: "Nye produkter",
  //  },
  //  bottomBar: {
  //    prevStep: "Tilbage",
  //    nextStep: "Næste",
  //  },
  //  OK: "OK",
  //  yes: "Ja",
  //  no: "Nej",
  //  color: "Farve",
  //  size: "Størrelse",
  //  length: "Længde",
  //  price: "Pris",
  //  orderNumber: "Ordrenummer",
  //  email: "Email",
  //  errorMessage: "Ups!",
  //  returnFreight: "Returfragt",
  //  loadingPortal: "Et øjeblik, indlæser retur-app...",
  //},
  nb: nb,
  no: nb,
  nn: nb,
});

export default strings;
