import { Card, CardContent, Grid, Typography } from "@mui/material";
import React, { useContext } from "react";
import strings from "../localization/localizedStrings";
import { ReturnContext } from "../contexts/ReturnContext";
import { useHistory } from "react-router-dom";
import BottomBar from "../components/BottomBar";

export default function PickReturnModePage(props) {
  const { invoice, setReturnMode, setActiveStep } = useContext(ReturnContext);

  const history = useHistory();

  React.useEffect(() => {
    setActiveStep(0);
  }, [setActiveStep]);

  const pickReturnMode = (returnMode) => {
    setReturnMode(returnMode);
    history.push("/select");
  };

  const back = () => {
    history.push("/");
  };

  return (
    <>
      <div style={{ display: "flex", justifyContent: "center", marginTop: "6%" }}>
        <Grid container justifyContent="center" alignItems="stretch">
          <Grid item></Grid>
          <Grid item xs>
            <Grid container direction="column" justifyContent="center" alignItems="stretch" spacing={2}>
              <Grid item xs style={{ textAlign: "center" }}>
                <Typography variant="h5">{strings.pageLanding.modeChoice}</Typography>
                <Grid container direction="row" justifyContent="center" alignItems="center" spacing={5} style={{ marginTop: "3vh" }}>
                  <Grid item>
                    {invoice.header.allowReturn && (
                      <Card style={{ cursor: "pointer" }} onClick={() => pickReturnMode("return")}>
                        <CardContent>
                          <Typography variant="h5">{strings.pageLanding.modeChoice1}</Typography>
                          <Typography variant="body2">{strings.pageLanding.modeChoice1Text}</Typography>
                        </CardContent>
                      </Card>
                    )}
                    {!invoice.header.allowReturn && (
                      <Card>
                        <CardContent>
                          <Typography variant="h5" style={{ textDecoration: "line-through" }}>
                            {strings.pageLanding.modeChoice1}
                          </Typography>
                          <Typography variant="body2">{strings.pageLanding.modeChoice1BlockedText}</Typography>
                        </CardContent>
                      </Card>
                    )}
                  </Grid>
                  <Grid item>
                    <Card style={{ cursor: "pointer" }} onClick={() => pickReturnMode("claim")}>
                      <CardContent>
                        <Typography variant="h5">{strings.pageLanding.modeChoice2}</Typography>
                        <Typography variant="body2">{strings.pageLanding.modeChoice2Text}</Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item></Grid>
          </Grid>
        </Grid>
      </div>
      <BottomBar previousStep={() => back()} nextStep={null} />
    </>
  );
}
