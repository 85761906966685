import React from "react";
import { Button, Card, CardContent, Grid, Typography } from "@mui/material";
import { useHistory } from "react-router-dom";
import CurrencyFormat from "./CurrencyFormat";
import noimg from "../../assets/noimg.png";
import { FilterContext } from "../../contexts/FilterContext";
import { useQueries } from "@tanstack/react-query";
import { getReplacementStoreText } from "../../services/returnService";
import { ReturnContext } from "../../contexts/ReturnContext";
import strings from "../../localization/localizedStrings";
import LatestProducts from "./LatestProducts";
import HotProducts from "./HotProducts";
import ImageCampaigns from "./ImageCampaigns";
import CampaignHeader from "./CampaignHeader";

export default function ProductGrid(props) {
  const { productsQuery, countsQuery, loadMore, filters, hasFilter, clearFilters } = React.useContext(FilterContext);
  const { installationId, invoice } = React.useContext(ReturnContext);
  const history = useHistory();

  const [replacementStoreTextQuery] = useQueries({
    queries: [
      {
        queryKey: ["replacementStoreTextQuery"],
        queryFn: async () => getReplacementStoreText(invoice.header === undefined ? "" : invoice.header?.orderNo),
        queryHash: `rstxt${installationId}${invoice.header?.orderNo}`,
        refetchOnWindowFocus: false,
        staleTime: Infinity,
      },
    ],
  });

  React.useEffect(() => {
  //  console.log(hasFilter());
  }, [hasFilter]);

  React.useEffect(() => {
    clearFilters();
  }, []);

  const replacementStoreTextHack = replacementStoreTextQuery.data;

  return (
    <>
      {/* <TextCampaigns /> */}
      {!hasFilter() && productsQuery.data?.length === 0 && filters.campaignId === 0 && (
        <div>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            style={{ marginTop: 15, marginBottom: 15 }}
          >
            <Grid item xs={12} sm={12} md={10} lg={6}>
              <Card>
                <CardContent>
                  <Typography variant="h5">{strings.returnSteps.two}</Typography>
                  <Typography variant="body2">
                    <span dangerouslySetInnerHTML={{ __html: replacementStoreTextHack }} />
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
          <ImageCampaigns
            setCampaign={(id) => {
              window.scrollTo({
                top: 0,
                left: 0,
                behavior: "smooth",
              });
            }}
          />
          <HotProducts />
          <LatestProducts />
        </div>
      )}
      {filters.campaignId !== 0 && <CampaignHeader campaignId={filters.campaignId} />}
      {hasFilter() && filters.campaignId === 0 && (
        <Grid container>
          <Grid item>
            <Typography variant="h5">
              {strings.productBrowser.productsFound.replace("#count#", countsQuery.data)}
            </Typography>
          </Grid>
        </Grid>
      )}
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        style={{ marginTop: 5, marginBottom: 0 }}
      ></Grid>
      <Grid
        container
        style={{
          marginTop: "20px",
          display: "grid",
          gridTemplateColumns: "repeat(auto-fit, minmax(180px, 0.5fr))",
          gridGap: "5px",
          backgroundImage:
            "url('data:image/svg+xml;utf8,<svg width='100%' height='80%' viewBox='0 0 100 100' xmlns='http://www.w3.org/2000/svg'><text x='50%' y='50%' style='font-family: sans-serif; font-size: 12px;' dominant-baseline='middle' text-anchor='middle'>Loading…</text></svg>')",
        }}
      >
        {productsQuery.data?.map((product, index) => {
          return (
            <div
              key={index}
              style={{ cursor: "pointer" }}
              onClick={() => {
                history.push("/replace/" + product.productIdentifier);
              }}
            >
              <img
                style={{
                  width: "100%",
                  height: "80%",
                  minHeight: "80%",
                  maxHeight: "80%",
                  minWidth: "100%",
                  maxWidth: "100%",
                  objectFit: "cover",
                }}
                src={product.imageUrlMedium ? product.imageUrlMedium : noimg}
                alt={product.productDescription}
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null; // prevents looping
                  currentTarget.src = noimg;
                }}
              />
              <Typography variant="caption" fontWeight="bold" component={"div"} m={0} p={0}>
                {product.programDescription.substring(0, 20)}
              </Typography>
              <Typography variant="caption" component={"div"} m={0} p={0}>
                {product.productDescription.substring(0, 20)}
              </Typography>
              <CurrencyFormat
                variant="caption"
                component={"span"}
                value={product.productPrice}
                renderText={(formattedValue) => {
                  <Typography variant="caption" component={"div"} m={0} p={0}>
                    {formattedValue}
                  </Typography>;
                }}
              />
            </div>
          );
        })}
      </Grid>
      {productsQuery.data && productsQuery.data?.length !== 0 && (
        <Grid container justifyItems={"center"} justifyContent={"center"}>
          <Grid item>
            <Button
              variant="outlined"
              color="primary"
              onClick={() => {
                loadMore();
              }}
            >
              {strings.productBrowser.loadMore}
            </Button>
          </Grid>
        </Grid>
      )}
    </>
  );
}
