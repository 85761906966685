import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { Buffer } from "buffer";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { BrowserRouter as Router } from "react-router-dom";
import { getPersistedObject, storePersistedObject } from "./services/storageService";
import axios from "axios";

const rootElement = document.getElementById("root");
const queryClient = new QueryClient();
const defaultInitParams = { installationId: 1, unit: "10", resolved: true };

const api = axios.create({
  baseURL: "/",
  headers: {
    "Content-type": "application/json",
  },
});

const fetchTokenByHost = async () => {
  try {
    const response = await api.get("home/tokenByHost");
    return response.data;
  } catch (error) {
    console.error("Error fetching tokenByHost:", error);
    return {
      host: "",
      token: "-",
    };
  }
};

const waitForToken = async () => {
  const tokenFromHost = await fetchTokenByHost();
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const tokenFromSearch = params.get("token");
  const tokenFromStorage = getPersistedObject("initParams");

  //console.log("tokenFromHost", tokenFromHost);

  const decodeToken = (token) => {
    return JSON.parse(Buffer.from(token, "base64").toString());
  };

  if (tokenFromSearch !== null) {
    storePersistedObject("initParams", { ...decodeToken(tokenFromSearch), resolved: true });
  } else if (tokenFromHost.token !== "-") {
    storePersistedObject("initParams", { ...decodeToken(tokenFromHost.token), resolved: true });
  } else if (tokenFromStorage !== null) {
    storePersistedObject("initParams", tokenFromStorage);
  } else {
    storePersistedObject("initParams", defaultInitParams);
  }
};

waitForToken();

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <QueryClientProvider client={queryClient}>
        <App />
      </QueryClientProvider>
    </Router>
  </React.StrictMode>,
  rootElement
);
