import { Step, StepLabel, Stepper, Typography } from "@mui/material";
import strings from "../localization/localizedStrings";
import React, { useMemo } from "react";
import { ReturnContext } from "../contexts/ReturnContext";
import useWindowDimensions from "../hooks/useWindowDimensions";

export default function StepTracker(props) {
  const { activeStep, returnMode, endGame, config } = React.useContext(ReturnContext);
  const { width } = useWindowDimensions();
  const steps = useMemo(() => {
    return returnMode === "return" && config.enableReplacementStore
      ? [strings.returnSteps.zero, strings.returnSteps.one, strings.returnSteps.two, strings.returnSteps.three]
      : returnMode === "return" && !config.enableReplacementStore
      ? [strings.returnSteps.zero, strings.returnSteps.one, strings.returnSteps.three]
      : [strings.claimSteps.zero, strings.claimSteps.one, strings.claimSteps.two];
  }, [returnMode, config.enableReplacementStore]);

  React.useEffect(() => {
    console.debug(steps);
  }, [steps]);

  if (endGame) {
    return (
      <div style={{ textAlign: "center" }}>
        <Typography variant="subtitle2" fontWeight={"bold"}>
          {strings.returnStep3.stepperText}
        </Typography>
      </div>
    );
  } else {
    return (
      <>
        {width < 800 && (
          <div style={{ textAlign: "center" }}>
            <Typography variant="caption">
              Step {activeStep + 1} / {steps.length}
            </Typography>{" "}
            <Typography variant="caption" style={{ fontWeight: "bold" }}>
              {steps[activeStep]}
            </Typography>
          </div>
        )}
        {width >= 800 && (
          <Stepper activeStep={activeStep}>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
        )}
      </>
    );
  }
}
