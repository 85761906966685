import React from "react";
import { LiveHelp } from "@mui/icons-material";
import { Dialog, DialogContentText, Grid } from "@mui/material";
import StepTracker from "./StepTracker";
import { useQueries } from "@tanstack/react-query";
import { getLogo, getHelpText } from "../services/returnService";
import { ReturnContext } from "../contexts/ReturnContext";
import useWindowDimensions from "../hooks/useWindowDimensions";
import strings from "../localization/localizedStrings";

export default function TopBar(props) {
  const [help, setHelp] = React.useState(false);
  const [defaultLogo, setDefaultLogo] = React.useState(
    "data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"
  );
  const { installationId, unit, shop, invoice, readyToMakeReturn } = React.useContext(ReturnContext);
  const { width } = useWindowDimensions();
  const cols = width <= 800 ? 12 : width < 1110 ? 12 : 8;
  const barHeight = "88px";

  const [helpTextQuery, logoQuery] = useQueries({
    queries: [
      {
        queryKey: ["helpTextQuery"],
        queryFn: async () => getHelpText(invoice.header === undefined ? "" : invoice.header?.orderNo),
        queryHash: `helpTextQuery${invoice.header?.orderNo}`,
        refetchOnWindowFocus: false,
        staleTime: Infinity,
        enabled: invoice.resolved,
      },
      {
        queryKey: ["logoQuery"],
        queryFn: async () => getLogo(shop === undefined ? "" : shop),
        queryHash: `logo${installationId}${invoice.header?.orderNo}`,
        refetchOnWindowFocus: false,
        staleTime: Infinity,
        enabled: unit !== undefined,
      },
    ],
  });

  React.useEffect(() => {
    if (!logoQuery.isSuccess) return;
    setDefaultLogo(logoQuery.data);
  }, [logoQuery.isSuccess, logoQuery.data]);

  const helpTextHack = helpTextQuery.data;

  return (
    <div style={{ height: barHeight }}>
      <div style={{ position: "fixed", width: "100%", height: "75px", top: 0, left: 0, zIndex: 10 }}>
        <Grid container justifyContent={"center"} style={{ backgroundColor: "white" }}>
          <Grid item>
            <div style={{ paddingTop: "4px", height: "60px" }}>
              <img src={defaultLogo} alt={"logo"} style={{ maxHeight: "55px" }} />
            </div>
          </Grid>
        </Grid>
        <Grid
          container
          justifyContent={"center"}
          style={{
            backgroundColor: "#f2f2f2",
            padding: "8px",
            borderTop: "1px solid #dedede",
            borderBottom: "1px solid #dedede",
          }}
        >
          <Grid item xs={cols}>
            {readyToMakeReturn() && <StepTracker />}
            {!readyToMakeReturn() && <span></span>}
          </Grid>
        </Grid>
      </div>
      <div
        onClick={() => {
          setHelp(true);
        }}
        style={{ position: "fixed", width: "auto", height: "75px", top: 10, right: 15, zIndex: 10, cursor: "pointer" }}
      >
        <LiveHelp style={{ visibility: helpTextQuery.isFetched ? "visible" : "hidden" }} />
      </div>
      <Dialog open={help} onClose={() => setHelp(false)} fullScreen={false}>
        <div style={{ margin: "2vh" }}>
          <DialogContentText>
            <span dangerouslySetInnerHTML={{ __html: helpTextHack }} />
          </DialogContentText>
        </div>
      </Dialog>
    </div>
  );
}
