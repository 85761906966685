import React from "react";
import { Button, Chip, Grid, IconButton, InputAdornment, Paper, TextField, Typography } from "@mui/material";
import strings from "../../localization/localizedStrings";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { ArrowDropDown, ArrowDropUp, Clear, Search } from "@mui/icons-material";
import { FilterContext } from "../../contexts/FilterContext";
import { ReturnContext } from "../../contexts/ReturnContext";

export default function FilterPopper(props) {
  const [expandedGroup, setExpandedGroup] = React.useState("");
  const [menuProps, setMenuProps] = React.useState({ width: "", top: "", left: "", display: "none" });
  const { width } = useWindowDimensions();
  const { config } = React.useContext(ReturnContext);
  const { productsQuery, countsQuery, filters, setFilters, clearFilters, hasFilter } = React.useContext(FilterContext);
  const { programsQuery, itemCategoryQuery, productGroupQuery, colorsQuery, sizeQuery, gendersQuery } = props;
  const [productGroupsFiltered, setProductGroupsFiltered] = React.useState([]);
  const menuRef = React.useRef();
  const filterRef = React.useRef();
  const filterInputRef = React.useRef();

  React.useEffect(() => {
    const filteredData = productGroupQuery.data?.filter((item) =>
      filters.itemCategories.some((category) => category === item.itemCategoryCode)
    );
   // console.log("Filtering product groups...");
    setProductGroupsFiltered(filteredData);
  }, [productGroupQuery, filters.itemCategories, filters.itemCategories.length]);

  React.useEffect(() => {
    if (!menuProps.display) return;
    filterInputRef.current?.focus();
  }, [menuProps.display]);

  React.useEffect(() => {
    setMenuProps({ display: "none" });
  }, [width]);

  const openPopper = () => {
    if (menuProps.display === "block") return;
    const { x, y, width } = filterRef.current.getBoundingClientRect();
    const useWidth = width + 100;
    setMenuProps({ width: `${useWidth}px`, top: y - 102 + "px", left: x - 50 + "px", display: "block" });
  };

  const closePopper = (e) => {
    setMenuProps({ display: "none" });
  };

  const clear = (e) => {
    clearFilters();
    closePopper(e);
    filterInputRef.current.value = "";
  };

  const FilterChips = () => {
    const { programs, itemCategories, productGroups, colors, sizes, genders } = filters;
    if (
      programs.length === 0 &&
      itemCategories.length === 0 &&
      productGroups.length === 0 &&
      colors.length === 0 &&
      sizes.length === 0 &&
      genders.length === 0
    ) {
      return <div></div>;
    } else {
      return (
        <>
          <Typography variant="caption" sx={{ fontWeight: "bold", mb: 1 }}>
            {strings.productBrowser.activeFilters}
          </Typography>
          <Grid container>
            {genders.map((m, i) => {
              const label = gendersQuery.data?.find((p) => p.code === m);
              return (
                <Grid item key={i}>
                  <Chip
                    label={label?.description}
                    onDelete={() => {
                      const itemIndex = genders.findIndex((f) => f === m);
                      const newFilter = [...genders];
                      newFilter.splice(itemIndex, 1);
                      setFilters((prev) => {
                        return { ...prev, genders: newFilter };
                      });
                    }}
                    size="small"
                  />
                </Grid>
              );
            })}

            {programs.map((m, i) => {
              const label = programsQuery.data?.find((p) => p.code === m);
              return (
                <Grid item key={i}>
                  <Chip
                    label={label?.description}
                    onDelete={() => {
                      const itemIndex = programs.findIndex((f) => f === m);
                      const newFilter = [...programs];
                      newFilter.splice(itemIndex, 1);
                      setFilters((prev) => {
                        return { ...prev, programs: newFilter };
                      });
                    }}
                    size="small"
                  />
                </Grid>
              );
            })}

            {itemCategories.map((m, index) => {
              const label = itemCategoryQuery.data?.find((p) => p.code === m);
              return (
                <Grid item key={index}>
                  <Chip
                    label={label?.description}
                    onDelete={() => {
                      const itemIndex = itemCategories.findIndex((f) => f === m);
                      const newFilter = [...itemCategories];
                      newFilter.splice(itemIndex, 1);
                      setFilters((prev) => {
                        return { ...prev, itemCategories: newFilter };
                      });
                    }}
                    size="small"
                  />
                </Grid>
              );
            })}

            {productGroups.map((m) => {
              const label = productGroupQuery.data?.find((p) => p.code === m);
              return (
                <Grid item>
                  <Chip
                    label={label?.description}
                    onDelete={() => {
                      const itemIndex = productGroups.findIndex((f) => f === m);
                      const newFilter = [...productGroups];
                      newFilter.splice(itemIndex, 1);
                      setFilters((prev) => {
                        return { ...prev, productGroups: newFilter };
                      });
                    }}
                    size="small"
                  />
                </Grid>
              );
            })}

            {colors.map((m) => {
              const label = colorsQuery.data?.find((p) => p.code === m);
              return (
                <Grid item>
                  <Chip
                    label={label?.description}
                    onDelete={() => {
                      const itemIndex = colors.findIndex((f) => f === m);
                      const newFilter = [...colors];
                      newFilter.splice(itemIndex, 1);
                      setFilters((prev) => {
                        return { ...prev, colors: newFilter };
                      });
                    }}
                    size="small"
                  />
                </Grid>
              );
            })}

            {sizes.map((m) => {
              const label = sizeQuery.data?.find((p) => p.code === m);
              return (
                <Grid item>
                  <Chip
                    label={label?.description}
                    onDelete={() => {
                      const itemIndex = sizes.findIndex((f) => f === m);
                      const newFilter = [...sizes];
                      newFilter.splice(itemIndex, 1);
                      setFilters((prev) => {
                        return { ...prev, sizes: newFilter };
                      });
                    }}
                    size="small"
                  />
                </Grid>
              );
            })}
          </Grid>
        </>
      );
    }
  };

  const GroupList = (props) => {
    const [slizeSize, setSlizeSize] = React.useState(12);
    const { filters, setFilters } = React.useContext(FilterContext);
    const { programs, itemCategories, productGroups, colors, sizes, genders } = filters;
    const currentGroupFilter =
      props.groupId === "gender"
        ? genders
        : props.groupId === "program"
        ? programs
        : props.groupId === "itemcat"
        ? itemCategories
        : props.groupId === "prodgroup"
        ? productGroups
        : props.groupId === "colors"
        ? colors
        : props.groupId === "sizes"
        ? sizes
        : programs;

    const checkItem = (e) => {
      const itemIndex = currentGroupFilter.findIndex((f) => f === e.target.value);

      if (itemIndex >= 0) {
        currentGroupFilter.splice(itemIndex, 1);
      } else {
        currentGroupFilter.push(e.target.value);
      }

      if (props.groupId === "gender") {
        setFilters((currentFilters) => {
          return { ...currentFilters, genders: currentGroupFilter };
        });
      }

      if (props.groupId === "program") {
        setFilters((currentFilters) => {
          return { ...currentFilters, programs: currentGroupFilter };
        });
      }

      if (props.groupId === "itemcat") {
        setFilters((currentFilters) => {
          return { ...currentFilters, itemCategories: currentGroupFilter, productGroups: [] };
        });
      }

      if (props.groupId === "prodgroup") {
        setFilters((currentFilters) => {
          return { ...currentFilters, productGroups: currentGroupFilter };
        });
      }

      if (props.groupId === "colors") {
        setFilters((currentFilters) => {
          return { ...currentFilters, colors: currentGroupFilter };
        });
      }

      if (props.groupId === "sizes") {
        setFilters((currentFilters) => {
          return { ...currentFilters, sizes: currentGroupFilter };
        });
      }
    };

    return (
      <Grid item style={{ cursor: "pointer" }}>
        <Grid
          container
          onClick={() => {
            setExpandedGroup(props.groupId === expandedGroup ? "" : props.groupId);
          }}
        >
          <Grid item xs>
            <Typography variant="caption" fontWeight={"bold"}>
              {props.title}
            </Typography>
          </Grid>
          <Grid item>
            {props.groupId === expandedGroup && <ArrowDropUp />}
            {props.groupId !== expandedGroup && <ArrowDropDown />}
          </Grid>
        </Grid>
        <hr />
        {expandedGroup === props.groupId && (
          <Grid
            container
            direction="column"
            justifyContent="flex-start"
            alignItems="stretch"
            spacing={1}
            style={{ marginTop: "5px", marginBottom: "15px" }}
          >
            <>
              {props.items?.slice(0, slizeSize).map((item, index) => {
                return (
                  <Grid item key={index}>
                    <input
                      type="checkbox"
                      name={"cbp"}
                      value={item.code}
                      checked={currentGroupFilter.find((f) => f === item.code)}
                      id={`cbp_${item.code}`}
                      onChange={(e) => {
                        checkItem(e);
                      }}
                    />{" "}
                    <label htmlFor={`cbp_${item.code}`} style={{ fontSize: "smaller" }}>
                      {" "}
                      {item.description}
                    </label>
                  </Grid>
                );
              })}
              {props.items?.length > slizeSize && (
                <Button variant="text" onClick={() => setSlizeSize((size) => size + 12)}>
                  {strings.productBrowser.showMoreItems}
                </Button>
              )}
            </>
          </Grid>
        )}
      </Grid>
    );
  };

  return (
    <div
      style={{
        width: width < 600 ? "60%" : width < 800 ? "70%" : width < 1000 ? "60%" : "50%",
        marginLeft: "auto",
        marginRight: "auto",
      }}
    >
      <Paper
        ref={menuRef}
        style={{
          padding: "15px",
          backgroundColor: "#F8F8F8",
          zIndex: 1999,
          position: "absolute",
          height: "auto",
          width: menuProps.width,
          top: menuProps.top,
          left: menuProps.left,
          display: menuProps.display,
        }}
      >
        <Grid
          container
          direction="column"
          justifyContent="flex-start"
          alignItems="stretch"
          style={{ marginTop: "50px", pointerEvents: "auto" }}
        >
          <Grid container>
            <FilterChips />
          </Grid>
          <div style={{ maxHeight: "50vh", overflowY: "scroll", overflowX: "hidden" }}>
            {config.enableCategoryGender && (
              <GroupList title={strings.productBrowser.itemgroup_gender} items={gendersQuery.data} groupId="gender" />
            )}
            {config.enableCategoryProgram && (
              <GroupList
                title={strings.productBrowser.itemgroup_program}
                items={programsQuery.data}
                groupId="program"
              />
            )}
            {config.enableCategoryItemCategory && (
              <GroupList
                title={strings.productBrowser.itemgroup_itemcat}
                items={itemCategoryQuery.data}
                groupId="itemcat"
              />
            )}
            {config.enableCategoryProductGroup && (
              <GroupList
                title={strings.productBrowser.itemgroup_prodgroup}
                items={productGroupsFiltered}
                groupId="prodgroup"
              />
            )}
            {config.enableCategoryColor && (
              <GroupList title={strings.productBrowser.itemgroup_color} items={colorsQuery.data} groupId="colors" />
            )}
            {config.enableCategorySize && (
              <GroupList title={strings.productBrowser.itemgroup_size} items={sizeQuery.data} groupId="sizes" />
            )}
          </div>
          <Grid item style={{ padding: "10px" }}>
            <Button
              variant="contained"
              fullWidth
              onClick={() => {
                productsQuery.refetch();
                closePopper();
              }}
              disabled={countsQuery.isFetching}
            >
              {countsQuery.isFetching && <Typography>...</Typography>}
              {!countsQuery.isFetching && (
                <Typography>
                  {strings.productBrowser.buttonShowItems.replace("#countsQuery#", countsQuery.data)}
                </Typography>
              )}
            </Button>
          </Grid>
        </Grid>
      </Paper>
      <TextField
        ref={filterRef}
        inputRef={filterInputRef}
        variant={"outlined"}
        size="small"
        fullWidth
        style={{ zIndex: 99999, pointerEvents: "auto" }}
        onClick={(e) => {
          openPopper(e);
        }}
        onBlur={(e) => {
          //closePopper(e);
        }}
        onKeyPress={(e) => {
          if (e.key === "Enter") {
            setFilters((currentFilter) => {
              return { ...currentFilter, searchTerm: filterInputRef.current.value };
            });
            window.setTimeout(() => {
              closePopper();
              productsQuery.refetch();
            }, 200);
          }
        }}
        InputProps={{
          style: { backgroundColor: "white", zIndex: 99999 },
          placeholder: strings.returnStep2.pagebarText,
          startAdornment: (
            <InputAdornment position="start">
              <IconButton>
                <Search />
              </IconButton>
            </InputAdornment>
          ),
          endAdornment: (
            <Clear
              style={{ cursor: "pointer" }}
              color="secondary"
              onClick={(e) => {
                e.stopPropagation();
                clear(e);
              }}
            />
          ),
        }}
      />
    </div>
  );
}
