import React from "react";
import { Buffer } from "buffer";
import { getPersistedObject, storeSessionObject } from "../services/storageService";
import { addItem, createCart, editItemQtyDec, editItemQtyInc, giftcardRefund, removeItem } from "../services/cartService";
import { Alert, AlertTitle, Dialog, DialogContent, DialogTitle, Snackbar, Typography } from "@mui/material";
import strings from "../localization/localizedStrings";
import { useMutation, useQuery } from "@tanstack/react-query";
import { createReturnRequest, getConfig } from "../services/returnService";

export const ReturnContext = React.createContext();

const ReturnContextProvider = (props) => {
  const [activeStep, setActiveStep] = React.useState(0);
  const [invoice, setInvoiceInternal] = React.useState({ resolved: false });
  const [returnConf, setReturnConf] = React.useState({ returnLines: [], replacementLines: [] });
  const [initParams, setInitParams] = React.useState(getPersistedObject("initParams"));
  const [tokenParams, setTokenParams] = React.useState({ email: "", orderNumber: "", fromQuery: false });
  const [replacementItems, setReplacementItems] = React.useState({ lines: [] });
  const [errorState, setErrorState] = React.useState({ hasError: false, errorMsg: "", severity: "error" });
  const [returnMode, setReturnMode] = React.useState("");
  const [endGame, setEndGame] = React.useState(false);
  const [cartSplashOpen, setCartSplashOpen] = React.useState(false);

  const configQuery = useQuery({
    queryKey: ["configQuery"],
    queryHash: `config${initParams.installationId}${invoice.header?.orderNo}`,
    queryFn: async () => getConfig(invoice.header?.orderNo ?? ""),
    refetchOnWindowFocus: false,
    staleTime: 0,
    initialData: { active: true },
  });

  const initReturnMutator = useMutation({
    mutationFn: async (args) => createCart(args),
    onSuccess: (cart) => setReplacementItems(cart),
    onError: (error) => setErrorState({ hasError: true, errorMsg: error.response?.data ?? error.message }),
  });

  const createReturnMutator = useMutation({
    mutationFn: async (args) => createReturnRequest(args),
    onSuccess: () => resetState(),
    onError: (error) => setErrorState({ hasError: true, errorMsg: error.response?.data ?? error.message }),
  });

  const giftcardRefundMutator = useMutation({
    mutationFn: async (args) => giftcardRefund(args),
    onSuccess: (cart) => setReplacementItems(cart),
    onError: (error) => {
      setErrorState({ hasError: true, errorMsg: error.response?.data ?? error.message });
    },
  });

  const cartAddMutator = useMutation({
    mutationFn: async (args) => addItem(args.cartNumber, args.itemNumber, args.variantId),
    onSuccess: (cart) => setReplacementItems(cart),
    onError: (error) => {
      setErrorState({ hasError: true, errorMsg: error.response?.data ?? error.message });
    },
  });

  const cartIncQtyMutation = useMutation({
    mutationFn: async (args) => editItemQtyInc(args.cartNumber, args.lineId),
    onSuccess: (cart) => setReplacementItems(cart),
    onError: (error) => {
      setErrorState({ hasError: true, errorMsg: error.response?.data ?? error.message });
    },
  });

  const cartDecQtyMutation = useMutation({
    mutationFn: async (args) => editItemQtyDec(args.cartNumber, args.lineId),
    onSuccess: (cart) => setReplacementItems(cart),
    onError: (error) => {
      setErrorState({ hasError: true, errorMsg: error.response?.data ?? error.message });
    },
  });

  const cartRemoveMutator = useMutation({
    mutationFn: async (args) => removeItem(args.cartNumber, args.id),
    onSuccess: (cart) => setReplacementItems(cart),
    onError: (error) => {
      setErrorState({ hasError: true, errorMsg: error.message });
    },
  });

  const disableUI = initReturnMutator.isLoading || giftcardRefundMutator.isLoading || cartAddMutator.isLoading || cartRemoveMutator.isLoading || createReturnMutator.isLoading || cartIncQtyMutation.isLoading || cartDecQtyMutation.isLoading;

  /* Resolve invoiceParams from querystring */
  const resolveTokenReturnParams = () => {
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const tokenFromSearch = params.get("oi");
    if (tokenFromSearch) {
      const decodedParamString = Buffer.from(tokenFromSearch, "base64").toString();
      return JSON.parse(decodedParamString);
    } else {
      if (window.location.hostname === "localhost") {
        return { orderNumber: "O10111303", email: "niklas@perfectit.se" };
      } else {
        return { orderNumber: "", email: "" };
      }
    }
  };

  React.useEffect(() => {
    if (createReturnMutator.isSuccess) resetState();
  }, [createReturnMutator.isSuccess]);

  React.useEffect(() => {
    const tokenParamsFromQuery = resolveTokenReturnParams();
    if (tokenParamsFromQuery === null) return;
    setTokenParams({ ...tokenParamsFromQuery, fromQuery: true });
  }, []);

  const setInvoice = (inv) => {
    storeSessionObject("invoice", inv);
    setInvoiceInternal({ ...inv, resolved: true });
    strings.setLanguage(inv.header.language);
  };

  const initReturn = async () => {
    await initReturnMutator.mutateAsync({
      installationId: initParams.installationId,
      unit: initParams.unit,
      invoiceNumber: invoice.header.invoiceNo,
      cartNumber: replacementItems?.cartNumber,
      lines: returnConf.returnLines,
    });
  };

  const createReturn = async (refundOption, checkoutResultCode, checkoutSessionData, checkoutSessionResult) => {
    //console.log("checkoutSessionData: ", checkoutSessionData);
    await createReturnMutator.mutateAsync({
      InstallationId: initParams.installationId,
      Unit: initParams.unit,
      cartNumber: replacementItems?.cartNumber,
      invoiceNumber: invoice.header.invoiceNo,
      refundOption: refundOption,
      lines: returnConf.returnLines,
      checkoutResultCode: checkoutResultCode,
      checkoutSessionData: checkoutSessionData,
      checkoutSessionResult: checkoutSessionResult,
      authRequired: checkoutSessionData !== undefined,
      returnMode: returnMode,
    });
  };

  const addGiftcardRefund = async () => {
    await giftcardRefundMutator.mutateAsync(replacementItems?.cartNumber);
  };

  const addReplacementItem = async (itemNumber, variantId) => {
    await cartAddMutator.mutateAsync({
      cartNumber: replacementItems.cartNumber,
      itemNumber,
      variantId: variantId === 0 ? null : variantId,
    });
    setCartSplashOpen(true);
    window.setTimeout(() => {
      setCartSplashOpen(false);
    }, 2000);
  };

  const replacementItemQtyInc = async (lineId) => {
    await cartIncQtyMutation.mutateAsync({ cartNumber: replacementItems?.cartNumber, lineId: lineId });
  };

  const replacementItemQtyDec = async (lineId) => {
    await cartDecQtyMutation.mutateAsync({ cartNumber: replacementItems?.cartNumber, lineId: lineId });
  };

  const removeReplacementItem = (id) => {
    cartRemoveMutator.mutate({ cartNumber: replacementItems?.cartNumber, id: id });
  };

  const removeReturnItem = (id) => {
    const cartLine = replacementItems.lines.find((f) => f.id === id);
    cartRemoveMutator.mutate({ cartNumber: replacementItems?.cartNumber, id: id });
    setReturnConf({
      ...returnConf,
      returnLines: returnConf.returnLines.filter((line) => line.invoiceLineId !== cartLine.fromInvoiceLineId),
    });
  };

  const readyToMakeReturn = () => {
    return initParams.resolved && invoice.resolved && returnMode !== "";
  };

  const readyToPickReturnMode = () => {
    return initParams.resolved && invoice.resolved;
  };

  const clearState = () => {
    setActiveStep(0);
    setInvoiceInternal({ resolved: false });
    setReturnConf({ returnLines: [], replacementLines: [] });
    setReturnMode("");
  };

  const resetState = () => {
    setEndGame(true);
    setActiveStep(0);
    setInvoiceInternal({ resolved: false });
    setReturnConf({ returnLines: [], replacementLines: [] });
    setTokenParams({ email: "", orderNumber: "", fromQuery: false });
    setReplacementItems({ lines: [] });
    setReturnMode("");
  };

  return (
    <ReturnContext.Provider
      value={{
        installationId: initParams.installationId,
        unit: initParams.unit,
        shop: initParams.shop,
        activeStep,
        setActiveStep,
        tokenParams,
        invoice,
        setInvoice,
        setInvoiceInternal,
        returnConf,
        setReturnConf,
        initReturn,
        createReturn,
        addGiftcardRefund,
        replacementItems,
        setReplacementItems,
        addReplacementItem,
        removeReplacementItem,
        replacementItemQtyInc,
        replacementItemQtyDec,
        removeReturnItem,
        readyToMakeReturn,
        readyToPickReturnMode,
        errorState,
        setErrorState,
        clearState,
        resetState,
        returnMode,
        setReturnMode,
        endGame,
        setEndGame,
        disableUI,
        config: configQuery.data,
      }}
    >
      <>
        {props.children}
        <Dialog
          onClose={() => {
            setCartSplashOpen(false);
          }}
          open={cartSplashOpen}
        >
          <DialogTitle>{strings.productBrowser.addCartMessage}</DialogTitle>
          <DialogContent>
            <Typography>{strings.productBrowser.addCartMessage}</Typography>
          </DialogContent>
        </Dialog>
        <Snackbar
          open={errorState.hasError}
          severity={errorState.severity ?? "error"}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          onClose={() => setErrorState({ hasError: false })}
          autoHideDuration={5000}
        >
          <Alert severity={errorState.severity ?? "error"} sx={{ fontSize: "1em" }}>
            <AlertTitle sx={{ fontSize: "1.5em" }}>{strings.errorMessage}</AlertTitle>
            {errorState.errorMsg}
          </Alert>
        </Snackbar>
      </>
    </ReturnContext.Provider>
  );
};

export default ReturnContextProvider;
