import React from "react";
import { Button, CircularProgress, Grid, MenuItem, TextField, Typography } from "@mui/material";
import { ReturnContext } from "../../contexts/ReturnContext";
import noimg from "../../assets/noimg.png";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import CurrencyFormat from "./CurrencyFormat";
import strings from "../../localization/localizedStrings";
import { Cancel, CheckCircle, Unpublished } from "@mui/icons-material";
import { useHistory } from "react-router-dom";
import { reduceVariantsToColorsWithStock, reduceVariantsToSizesWithStock, reduceVariantsToLengthsWithStock } from "../../services/variantService";
//import "./productCard.css";

export default function ProductCard(props) {
  const [pickedColorCode, setPickedColorCode] = React.useState("");
  const [pickedSizeCode, setPickedSizeCode] = React.useState("");
  const [pickedLengthCode, setPickedLengthCode] = React.useState("");
  const { width } = useWindowDimensions();
  const { addReplacementItem, disableUI } = React.useContext(ReturnContext);
  const { productQuery } = props;
  const history = useHistory();
  const lrImgMargins = width < 750 ? "0%" : width < 1000 ? "15%" : width < 1400 ? "20%" : width < 1600 ? "30%" : "35%";

  const colors = React.useMemo(() => reduceVariantsToColorsWithStock(productQuery?.data?.variants), [productQuery.data]);
  const sizes = React.useMemo(() => reduceVariantsToSizesWithStock(productQuery?.data?.variants, pickedColorCode), [productQuery.data, pickedColorCode]);
  const lengths = React.useMemo(() => reduceVariantsToLengthsWithStock(productQuery?.data?.variants, pickedColorCode, pickedSizeCode), [productQuery.data, pickedColorCode, pickedSizeCode]);

  const showColors = React.useCallback(() => {
    return productQuery.data?.variants?.find((f) => f.colorCode !== "") !== undefined;
  }, [productQuery.data]);

  const showSizes = React.useCallback(() => {
    return productQuery.data?.variants?.find((f) => f.sizeCode !== "") !== undefined;
  }, [productQuery.data]);

  const showLengths = React.useCallback(() => {
    return productQuery.data?.variants?.find((f) => f.lengthCode !== "") !== undefined;
  }, [productQuery.data]);

  const selectedVariantFromProperties = React.useMemo(() => {
    const pickedVariant = productQuery.data?.variants?.filter((f) => f.colorCode === pickedColorCode && f.sizeCode === pickedSizeCode && f.lengthCode === pickedLengthCode)[0];
    console.log(pickedVariant);
    return pickedVariant;
  }, [pickedColorCode, pickedSizeCode, pickedLengthCode]);
  const variantId = selectedVariantFromProperties?.variantId;
  const variantPrice = productQuery.data?.productPrices.find((f) => f.variantId === variantId)?.salesPrice ?? 0.0;
  const itemOnStock = (selectedVariantFromProperties ?? productQuery.data)?.remainingQuantity > 0;

  if (productQuery.isFetching) {
    return (
      <div style={{ marginLeft: "auto", marginRight: "auto", marginTop: "10vh", width: "10vw" }}>
        <CircularProgress />
      </div>
    );
  }

  return (
    <div style={{ marginRight: lrImgMargins, marginLeft: lrImgMargins, marginBottom: "100px" }}>
      <div style={{ textAlign: "right" }}>
        <Cancel
          fontSize="large"
          onClick={() => {
            history.push("/replace");
          }}
        />
      </div>
      <div style={{ textAlign: "center" }}>
        <img
          style={{
            width: "100%",
            height: "80%",
            minHeight: "80%",
            maxHeight: "80%",
            minWidth: "100%",
            maxWidth: "100%",
            objectFit: "cover",
          }}
          src={productQuery.data?.imageUrlLarge ? productQuery.data?.imageUrlLarge : noimg}
          alt={productQuery.data?.productDescription}
          onError={(e) => {
            e.target.onerror = null;
            e.target.src = noimg;
          }}
        />
      </div>
      <div style={{ width: "100%" }}>
        <div>
          <Typography variant="h5" fontWeight={"bold"}>
            {productQuery.data?.programDescription}
          </Typography>
          <Typography variant="h6">{productQuery.data?.productDescription}</Typography>
        </div>
        <div style={{ marginTop: "10px" }}>
          <CurrencyFormat value={variantPrice > 0 ? variantPrice : productQuery.data?.productPrice} />
        </div>
        <div style={{ marginTop: "10px" }}>{productQuery.data?.productDescription2}</div>
        <div style={{ marginTop: "30px" }}>
          {productQuery.data?.variants?.length > 0 && (
            <>
              {showColors() && (
                <div style={{ marginTop: "20px" }}>
                  <TextField
                    size="small"
                    select
                    fullWidth
                    label={strings.productBrowser.itemgroup_color}
                    InputLabelProps={{ shrink: true }}
                    value={pickedColorCode}
                    onChange={(e) => {
                      setPickedColorCode(e.target.value);
                    }}
                  >
                    {colors?.map((variant, index) => {
                      return (
                        <MenuItem key={index} value={variant.colorCode}>
                          <Grid container>
                            <Grid item xs>
                              <Typography sx={{ textDecoration: variant.stockQty > 0 ? "dashed" : "line-through" }}>{variant.colorDesc}</Typography>
                            </Grid>
                          </Grid>
                        </MenuItem>
                      );
                    })}
                  </TextField>
                </div>
              )}
              {showSizes() && (
                <div style={{ marginTop: "20px" }}>
                  <TextField
                    size="small"
                    select
                    fullWidth
                    label={strings.productBrowser.itemgroup_size}
                    InputLabelProps={{ shrink: true }}
                    value={pickedSizeCode}
                    onChange={(e) => {
                      setPickedSizeCode(e.target.value);
                    }}
                  >
                    {sizes?.map((variant, index) => {
                      return (
                        <MenuItem key={index} value={variant.sizeCode}>
                          <Grid container>
                            <Grid item xs>
                              <Typography sx={{ textDecoration: variant.stockQty > 0 ? "dashed" : "line-through" }}>{variant.sizeDesc}</Typography>
                            </Grid>
                          </Grid>
                        </MenuItem>
                      );
                    })}
                  </TextField>
                </div>
              )}
              {showLengths() && (
                <div style={{ marginTop: "20px" }}>
                  <TextField
                    size="small"
                    select
                    fullWidth
                    label={strings.productBrowser.itemgroup_length}
                    InputLabelProps={{ shrink: true }}
                    value={pickedLengthCode}
                    onChange={(e) => {
                      setPickedLengthCode(e.target.value);
                    }}
                  >
                    {lengths?.map((variant, index) => {
                      return (
                        <MenuItem key={index} value={variant.lengthCode}>
                          <Grid container>
                            <Grid item xs>
                              <Typography sx={{ textDecoration: variant.stockQty > 0 ? "dashed" : "line-through" }}>{variant.lengthDesc}</Typography>
                            </Grid>
                          </Grid>
                        </MenuItem>
                      );
                    })}
                  </TextField>
                </div>
              )}
            </>
          )}
        </div>
        <div style={{ marginTop: "30px" }}>
          <Button
            variant="contained"
            fullWidth
            disabled={disableUI || !itemOnStock}
            onClick={async () => {
              await addReplacementItem(productQuery.data?.productIdentifier, selectedVariantFromProperties?.variantId);
              history.push("/replace");
            }}
          >
            {disableUI && (
              <span>
                <CircularProgress size={16} sx={{ color: "black", fontSize: "0.5rem" }} />
              </span>
            )}
            {!disableUI && <span>{strings.productBrowser.buttonAddCart}</span>}
          </Button>
        </div>
        <div style={{ marginTop: "20px", marginLeft: "10%", marginRight: "10%" }}>
          {itemOnStock && (
            <Grid container>
              <Grid item>
                <CheckCircle color="success" />
              </Grid>
              <Grid item>
                <Typography>{strings.productBrowser.inStock}</Typography>
              </Grid>
            </Grid>
          )}
          {!itemOnStock && (
            <Grid container>
              <Grid item>
                <Unpublished color="warning" />
              </Grid>
              <Grid item>
                <Typography>{strings.productBrowser.outOfStock}</Typography>
              </Grid>
            </Grid>
          )}

          <Typography variant="caption" style={{ textDecoration: "underline" }} component="div">
            {strings.productBrowser.prodInfoHeader}
          </Typography>
          <Grid container>
            <Grid item xs>
              <Typography variant="caption" fontWeight={"bold"} component="div">
                {strings.productBrowser.prodInfoItemNo}:
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="caption" component="div">
                {productQuery.data?.productIdentifier}
              </Typography>
            </Grid>
          </Grid>
        </div>
      </div>
    </div>
  );
}
