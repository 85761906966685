import { CircularProgress, Grid, Typography } from "@mui/material";
import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import TopBar from "../components/TopBar";
import { ReturnContext } from "../contexts/ReturnContext";
import PickReturnModePage from "./PickReturnModePage";
import ConfirmPage from "./ConfirmPage";
import LoginPage from "./LoginPage";
import ReturnPage from "./ReturnPage";
import StorePage from "./StorePage";
import strings from "../localization/localizedStrings";
import ThanksPage from "./ThanksPage";
import HandleRedirectPage from "./HandleRedirectPage";

const OnlyReadyToMakeReturn = ({ children }) => {
  const { readyToMakeReturn, endGame } = React.useContext(ReturnContext);
  if (!readyToMakeReturn()) {
    if (endGame) {
      return <Redirect to="/thanks" />;
    } else {
      return <Redirect to="/" />;
    }
  }
  return children;
};

const OnlyReadyToPickReturnMode = ({ children }) => {
  const { readyToPickReturnMode } = React.useContext(ReturnContext);
  if (!readyToPickReturnMode()) {
    return <Redirect to="/" />;
  }
  return children;
};

const OnlyEndgame = ({ children }) => {
  const { endGame } = React.useContext(ReturnContext);
  if (!endGame) return <Redirect to="/" />;
  return children;
};

export default function StartPage(props) {
  const { config } = React.useContext(ReturnContext);

  if (!config.active) {
    return (
      <div style={{ display: "flex", justifyContent: "center", marginTop: "15%" }}>
        <Grid container direction="column" justifyContent="center" alignItems="stretch" spacing={2}>
          <Grid item xs style={{ textAlign: "center" }}>
            <CircularProgress color="primary" />
          </Grid>
          <Grid item xs style={{ textAlign: "center" }}>
            <Typography>{strings.loadingPortal}</Typography>
          </Grid>
        </Grid>
      </div>
    );
  } else {
    return (
      <>
        <TopBar />
        <Switch>
          <Route exact path={"/"}>
            <LoginPage />
          </Route>
          <Route path={"/returnMode"}>
            <OnlyReadyToPickReturnMode>
              <PickReturnModePage />
            </OnlyReadyToPickReturnMode>
          </Route>
          <Route path={"/select"}>
            <OnlyReadyToMakeReturn>
              <ReturnPage />
            </OnlyReadyToMakeReturn>
          </Route>
          <Route path={["/replace/:productIdentifier", "/replace"]}>
            <OnlyReadyToMakeReturn>
              <StorePage />
            </OnlyReadyToMakeReturn>
          </Route>
          <Route path="/confirm">
            <OnlyReadyToMakeReturn>
              <ConfirmPage />
            </OnlyReadyToMakeReturn>
          </Route>
          <Route path="/thanks">
            <OnlyEndgame>
              <ThanksPage />
            </OnlyEndgame>
          </Route>
          <Route path="/hrr">
            <HandleRedirectPage />
          </Route>
        </Switch>
      </>
    );
  }
}
