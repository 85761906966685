import { CircularProgress, Grid, Typography } from "@mui/material";
import strings from "../localization/localizedStrings";
import React, { useContext } from "react";
import FilterContextProvider from "../contexts/FilterContext";
import { useHistory, useParams } from "react-router-dom";
import {
  getBaseColors,
  getBaseSizes,
  getGenders,
  getItemCategories,
  getProduct,
  getProductGroups,
  getPrograms,
} from "../services/productService";
import { ReturnContext } from "../contexts/ReturnContext";
import { useQueries } from "@tanstack/react-query";
import PageBar from "../components/PageBar";
import useWindowDimensions from "../hooks/useWindowDimensions";
import ProductCard from "../components/Product/ProductCard";
import FilterPopper from "../components/Product/FilterPopper";
import ProductGrid from "../components/Product/ProductGrid";
import BottomBar from "../components/BottomBar";

function StoreContent(props) {
  const [isLoading, setIsLoading] = React.useState(true);
  const { invoice, setActiveStep } = useContext(ReturnContext);
  const { productIdentifier } = useParams();
  const { width } = useWindowDimensions();
  let history = useHistory();
  const [programsQuery, itemCategoryQuery, productGroupQuery, colorsQuery, sizeQuery, gendersQuery, productQuery] =
    useQueries({
      queries: [
        {
          queryKey: ["programs"],
          queryFn: async () => getPrograms(),
          refetchOnWindowFocus: false,
          staleTime: Infinity,
        },
        {
          queryKey: ["itemcat"],
          queryFn: async () => getItemCategories(),
          refetchOnWindowFocus: false,
          staleTime: Infinity,
        },
        {
          queryKey: ["prodgroup"],
          queryFn: async () => getProductGroups(),
          refetchOnWindowFocus: false,
          staleTime: Infinity,
        },
        {
          queryKey: ["colors"],
          queryFn: async () => getBaseColors(),
          refetchOnWindowFocus: false,
          staleTime: Infinity,
        },
        {
          queryKey: ["sizes"],
          queryFn: async () => getBaseSizes(),
          refetchOnWindowFocus: false,
          staleTime: Infinity,
        },
        {
          queryKey: ["genders"],
          queryFn: async () => getGenders(),
          refetchOnWindowFocus: false,
          staleTime: Infinity,
        },
        {
          queryKey: ["product"],
          queryHash: productIdentifier,
          queryFn: async () => getProduct(productIdentifier, invoice.header.customerNo),
          refetchOnWindowFocus: false,
          staleTime: 0,
          enabled: typeof productIdentifier !== "undefined",
          retry: 0,
        },
      ],
    });

  React.useEffect(() => {
    if (
      programsQuery.isLoading ||
      itemCategoryQuery.isLoading ||
      productGroupQuery.isLoading ||
      colorsQuery.isLoading ||
      sizeQuery.isLoading ||
      gendersQuery.isLoading
    )
      return;
    setIsLoading(false);
  }, [programsQuery, itemCategoryQuery, productGroupQuery, colorsQuery, sizeQuery, gendersQuery]);

  React.useEffect(() => {
    setActiveStep(2);
  }, [setActiveStep]);

  const persistStep = () => {
    history.push("/confirm");
  };

  const back = () => {
    if (productIdentifier === undefined) {
      history.push("/select");
    } else {
      history.push("/replace");
    }
  };

  if (isLoading) {
    return (
      <div style={{ display: "flex", justifyContent: "center", marginTop: "6%" }}>
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="stretch"
          spacing={2}
          xs={12}
          md={5}
          xl={4}
        >
          <Grid item xs style={{ textAlign: "center" }}>
            <CircularProgress color="primary" />
          </Grid>
          <Grid item xs style={{ textAlign: "center" }}>
            <Typography>{strings.productBrowser.loadingStore}</Typography>
          </Grid>
        </Grid>
      </div>
    );
  } else {
    return (
      <>
        <PageBar width={width} previousStep={() => back()} nextStep={() => persistStep()} />
        <div
          style={{
            position: "fixed",
            background: "transparent",
            width: "100%",
            height: "60px",
            top: "99px",
            paddingTop: "9px",
            left: 0,
            zIndex: 2,
            pointerEvents: "none",
          }}
        >
          <FilterPopper
            programsQuery={programsQuery}
            itemCategoryQuery={itemCategoryQuery}
            productGroupQuery={productGroupQuery}
            colorsQuery={colorsQuery}
            sizeQuery={sizeQuery}
            gendersQuery={gendersQuery}
          />
        </div>

        <div style={{ padding: "10px", marginTop: "10px" }}>
          {productIdentifier !== undefined && <ProductCard productQuery={productQuery} />}
          {productIdentifier === undefined && (
            <div style={{ maxWidth: "1700px", marginLeft: "auto", marginRight: "auto" }}>
              <ProductGrid />
            </div>
          )}
          <BottomBar previousStep={() => back()} nextStep={() => persistStep()} />
        </div>
      </>
    );
  }
}

export default function StorePage(props) {
  return (
    <FilterContextProvider>
      <StoreContent />
    </FilterContextProvider>
  );
}
