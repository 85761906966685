import { ArrowCircleLeft, ArrowCircleRight, Delete, ShoppingBag } from "@mui/icons-material";
import { Badge, CircularProgress, Drawer, Grid, IconButton, Typography } from "@mui/material";
import React, { useContext } from "react";
import { ReturnContext } from "../contexts/ReturnContext";
import noimg from "../assets/noimg.png";
import strings from "../localization/localizedStrings";
import CurrencyFormat from "./Product/CurrencyFormat";
import useWindowDimensions from "../hooks/useWindowDimensions";

export default function Cart(props) {
  const [expanded, setExpanded] = React.useState(false);
  const {
    replacementItems,
    removeReplacementItem,
    replacementItemQtyInc,
    replacementItemQtyDec,
    activeStep,
    disableUI,
  } = useContext(ReturnContext);
  const { width } = useWindowDimensions();
  const cartWidth = width < 900 ? "80vw" : width < 1200 ? "40%" : "50vw";
  const cartLines = replacementItems.lines.filter((f) => f.quantity > 0);
  const cartAmount = React.useMemo(() => {
    return cartLines.reduce((amount, line) => amount + line.amountIncludingVAT, 0.0);
  }, [cartLines]);

  return (
    <div>
      <Badge badgeContent={cartLines.length} color="secondary" showZero>
        <ShoppingBag
          style={{ color: "white", cursor: "pointer" }}
          onClick={() => {
            setExpanded(true);
          }}
        />
      </Badge>
      <Drawer
        anchor="right"
        open={expanded}
        onClose={() => {
          setExpanded(false);
        }}
        PaperProps={{ style: { width: cartWidth } }}
      >
        <div style={{ padding: "20px" }}>
          <Grid container direction="row" justifyContent="space-between" alignItems="flex-start">
            <Grid item>
              <Typography variant="h5">{strings.cart.header}</Typography>
            </Grid>
            <Grid item>
              <CurrencyFormat value={cartAmount} />
            </Grid>
          </Grid>
          <hr />
          {cartLines.map((item, index) => {
            return (
              <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start" key={index}>
                <Grid item>
                  <img
                    style={{ width: "12vh", height: "50%", maxHeight: "50%", objectFit: "cover" }}
                    src={item.imageUrl ? item.imageUrl : noimg}
                    alt={item.description}
                  />
                </Grid>
                <Grid item xs style={{ marginLeft: "2vh" }}>
                  <Typography variant="h6">{item.description}</Typography>
                  <Typography variant="caption">
                    {item.colorDescription} {item.sizeDescription}
                    {item.lengthDescription}
                  </Typography>
                  <Typography variant="body2">
                    <CurrencyFormat value={item.amountIncludingVAT} />
                  </Typography>
                  <Grid container direction="row" justifyContent="flex-start" alignItems="center">
                    <Grid item>
                      <IconButton
                        disabled={item.type !== 2}
                        size="large"
                        onClick={async (line) => {
                          await replacementItemQtyDec(item.id);
                        }}
                      >
                        <ArrowCircleLeft color={item.type !== 2 ? "disabled" : "primary"} />
                      </IconButton>
                    </Grid>
                    <Grid item>{item.quantity}</Grid>
                    <Grid item>
                      <IconButton
                        disabled={item.type !== 2}
                        size="large"
                        onClick={async (line) => {
                          await replacementItemQtyInc(item.id);
                        }}
                      >
                        <ArrowCircleRight color={item.type !== 2 ? "disabled" : "primary"} />
                      </IconButton>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  {disableUI && <CircularProgress size={18} sx={{ color: "black" }} />}
                  {!disableUI && (
                    <Delete
                      fontSize="large"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        removeReplacementItem(item.id);
                      }}
                    />
                  )}
                </Grid>
              </Grid>
            );
          })}
        </div>
      </Drawer>
    </div>
  );
}
