import React from "react";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import strings from "../localization/localizedStrings";
import { Alert, AlertTitle, Button, Paper, ToggleButton, ToggleButtonGroup } from "@mui/material";
import CurrencyFormat from "./Product/CurrencyFormat";
import ReturnChoicesMobile from "./ReturnChoicesMobile";
import { ReturnContext } from "../contexts/ReturnContext";
import { getReturnReasons, uploadProductImage } from "../services/returnService";
import { useQuery } from "@tanstack/react-query";
import noimg from "../assets/noimg.png";

export default function ReturnLinesMobile(props) {
  const { invoice, returnConf, setReturnConf, setErrorState, returnMode } = React.useContext(ReturnContext);

  const returnReasonsQuery = useQuery({
    queryKey: ["returnReasonsQuery"],
    queryFn: async () => getReturnReasons(),
    refetchOnWindowFocus: false,
    staleTime: Infinity,
  });

  const editReturnLine = (invoiceLine) => {
    return (
      returnConf.returnLines.find((f) => f.guid === invoiceLine.guid) ?? {
        guid: invoiceLine.guid,
        invoiceLineId: invoiceLine.id,
        amountIncludingVAT: invoiceLine.amountIncludingVAT,
      }
    );
  };

  const setReturnLine = (line) => {
    const lines = [...returnConf.returnLines];
    let index = lines.findIndex((f) => f.guid === line.guid);
    while (index >= 0) {
      lines.splice(index, 1);
      index = lines.findIndex((f) => f.guid === line.guid);
    }
    if (line.returnChoice === "1") lines.push(line);
    setReturnConf({ ...returnConf, returnLines: lines });
  };

  const setReturnChoice = (invoiceLine, choice) => {
    let returnLine = editReturnLine(invoiceLine);
    returnLine.returnChoice = choice;
    setReturnLine(returnLine);
  };

  const setReturnChoice2 = (invoiceLine, choice) => {
    let returnLine = editReturnLine(invoiceLine);
    returnLine.returnChoice2 = choice;
    if (choice === "0") {
      returnLine.replacementVariantId = null;
    }
    setReturnLine(returnLine);
  };

  const setReturnReason = (invoiceLine, reason) => {
    let returnLine = editReturnLine(invoiceLine);
    returnLine.returnReason = reason.code;
    returnLine.returnReasonDesc = reason.description;
    returnLine.photoRequired = reason.photoRequired;
    returnLine.commentRequired = reason.commentRequired;
    returnLine.returnComment = "";
    setReturnLine(returnLine);
  };

  const setReplacementItem = async (invoiceLine, replace) => {
    let returnLine = editReturnLine(invoiceLine);
    returnLine.replacementItem = replace;
    setReturnLine(returnLine);
  };

  const setReplacementVariant = async (invoiceLine, variant) => {
    let returnLine = editReturnLine(invoiceLine);
    returnLine.replacementVariantId = variant == null ? undefined : variant.variantId;
    returnLine.replacementVariant = variant == null ? undefined : variant;
    setReturnLine(returnLine);
  };

  const setReturnComment = (invoiceLine, comment) => {
    let returnLine = editReturnLine(invoiceLine);
    returnLine.returnComment = comment;
    setReturnLine(returnLine);
  };

  const uploadImage = async (invoiceLine, selectedFile) => {
    try {
      var response = await uploadProductImage(invoiceLine.guid, selectedFile);
      let returnLine = editReturnLine(invoiceLine);
      returnLine.connectedImageId = response.fileId;
      returnLine.lineReqPassed = true;
      returnLine.uploadedImage = URL.createObjectURL(selectedFile);
      setReturnLine(returnLine);
    } catch (error) {
      setErrorState({ hasError: true, errorMsg: error.response.data, severity: "warning" });
    }
  };

  const deleteImage = (invoiceLine) => {
    let returnLine = editReturnLine(invoiceLine);
    returnLine.connectedImageId = null;
    returnLine.lineReqPassed = false;
    returnLine.uploadedImage = null;
    setReturnLine(returnLine);
  };

  React.useEffect(() => {
    //console.log(returnConf);
  }, [returnConf]);

  return (
    <>
      {invoice?.lines?.map((line, index) => {
        const returnLine = returnConf.returnLines?.find((f) => f.guid === line.guid);
        return (
          <Paper variant="outlined" key={index} style={{ marginBottom: "10px", padding: "10px" }}>
            <Grid container direction="column" justifyContent="center" alignItems="center" spacing={2}>
              <Grid item>
                <Typography variant="h6" component={"div"}>
                  {line.description}
                </Typography>
                <Typography variant="caption">
                  {line.colorDesc} {line.sizeDesc} {line.lengthDesc}
                </Typography>
                <Typography variant="caption">
                  <CurrencyFormat value={line.amountIncludingVAT} />
                </Typography>
              </Grid>
              <Grid item>
                <div
                  style={{
                    textAlign: "center",
                    width: 200,
                    maxHeight: 300,
                  }}
                >
                  <img
                    src={!line.imageUrl || line.imageUrl === "" ? noimg : line.imageUrl}
                    alt=""
                    onError={(e) => {
                      e.target.onerror = null;
                      e.target.src = noimg;
                    }}
                    style={{ maxWidth: 200, maxHeight: 300 }}
                  />
                </div>
                <div>
                  {returnLine?.returnChoice === "1" && (
                    <ToggleButtonGroup
                      color="primary"
                      value={returnLine?.returnChoice ?? "0"}
                      exclusive
                      onChange={(e) => {
                        setReturnChoice(line, e.target.value);
                      }}
                      aria-label="Platform"
                      fullWidth
                      size="small"
                      style={{ maxWidth: 200 }}
                    >
                      <ToggleButton value="0" size="small" style={{ fontSize: 10 }}>
                        {strings.returnStep1.optKeep}
                      </ToggleButton>
                      <ToggleButton value="1" size="small" style={{ fontSize: 10 }}>
                        {returnMode === "return" ? strings.returnStep1.optReturn : strings.returnStep1.optClaim}
                      </ToggleButton>
                    </ToggleButtonGroup>
                  )}
                  {returnLine?.returnChoice !== "1" && (
                    <ToggleButtonGroup
                      color="primary"
                      value={returnLine?.returnChoice ?? "0"}
                      exclusive
                      onChange={(e) => {
                        setReturnChoice(line, e.target.value);
                      }}
                      aria-label="Platform"
                      fullWidth
                      size="small"
                    >
                      <ToggleButton value="1" size="small" style={{ fontSize: 10 }}>
                        {returnMode === "return" ? strings.returnStep1.optReturn : strings.returnStep1.optClaim}
                      </ToggleButton>
                    </ToggleButtonGroup>
                  )}
                </div>
              </Grid>

              <Grid item xs>
                <Grid container direction="column" justifyContent="flex-start" alignItems="stretch">
                  <Grid item>
                    <div style={{ marginTop: "0px", marginLeft: "0px" }}>
                      <ReturnChoicesMobile
                        line={line}
                        returnReasons={returnReasonsQuery.data}
                        setReturnReason={(line, code) => setReturnReason(line, code)}
                        setReturnChoice2={(line, code) => setReturnChoice2(line, code)}
                        setReplacementItem={(line, replace) => setReplacementItem(line, replace)}
                        setReplacementVariant={(line, variant) => setReplacementVariant(line, variant)}
                        setReturnComment={(line, comment) => setReturnComment(line, comment)}
                      />
                    </div>
                  </Grid>
                </Grid>
              </Grid>

              {returnLine?.photoRequired && (
                <>
                  {!returnLine?.uploadedImage && (
                    <Grid item>
                      <Alert severity="warning">
                        <div>
                          {strings.returnStep1.photoWarning}{" "}
                          <input
                            id="file"
                            type="file"
                            accept="image/*"
                            onChange={(e) => {
                              uploadImage(line, e.target.files[0]);
                            }}
                          ></input>
                        </div>
                      </Alert>
                    </Grid>
                  )}
                  {returnLine?.uploadedImage && (
                    <>
                      <Grid item>
                        <Alert severity="success" onClose={() => deleteImage(line)}>
                          <AlertTitle>{strings.returnStep1.photoOk}</AlertTitle>
                          <Button
                            variant="outlined"
                            onClick={() => {
                              const newWindow = window.open(returnLine.uploadedImage, "_blank", "noopener,noreferrer");
                              if (newWindow) newWindow.opener = null;
                            }}
                          >
                            {strings.returnStep1.viewImage}
                          </Button>
                        </Alert>
                      </Grid>
                    </>
                  )}
                  {returnLine?.commentRequired && returnLine.returnComment === "" && (
                    <Grid item>
                      <Alert severity="warning">
                        <div>{strings.returnStep1.commentWarning}</div>
                      </Alert>
                    </Grid>
                  )}
                </>
              )}
            </Grid>
          </Paper>
        );
      })}
    </>
  );
}
